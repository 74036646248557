import { AppLayout, AppLayoutProps, ContentLayout, Flashbar, FlashbarProps, Header, Link, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useCallback, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { LoadingStatus } from 'src/components/context/AppContextModels';
import { useAppContext } from 'src/components/context/AppContextProvider';
import { DFPHomePageSideNavigation } from 'src/components/dfp-home-page/DFPHomePageSideNavigation';
import { ErrorFallback } from 'src/components/generic-components/ErrorFallback';
import { LoadingSpinner } from 'src/components/generic-components/LoadingSpinner';
import { appLayoutAriaLabels } from 'src/i18n-strings';
import { logger } from 'src/logger';
import { InfoPanelDetailsHelpPanel } from '../admin-components/applications/admin-manage-application/InfoPanelDetails';
import { ResetButton } from '../configurable-dashboard/components/ResetButton';
import { TeamTabs } from './components/TeamTabs';
import { CONSTANTS } from './constants';

/**
 * FinTechTeamHomePage component that serves as the main dashboard for team information
 */
export const FinTechTeamHomePage: React.FC = () => {
  const appLayoutRef = useRef<AppLayoutProps.Ref>(null);
  const { contextLoadingStatus, teamPageContent } = useAppContext();
  const [flashbarItems, setFlashbarItems] = useState<FlashbarProps.MessageDefinition[]>([]);
  const [toolsOpen, setToolsOpen] = useState(false);

  const resetRef = useRef<() => void>();

  /**
   * Handles error messages and logging
   */
  const handleError = useCallback((error: Error) => {
    setFlashbarItems((prev) => [
      ...prev,
      {
        type: 'error',
        content: CONSTANTS.ERROR_MESSAGES.GENERIC_ERROR,
        dismissible: true,
        onDismiss: () => setFlashbarItems((prev) => prev.filter((item) => item.content !== CONSTANTS.ERROR_MESSAGES.GENERIC_ERROR))
      }
    ]);
    logger.error('FinTechTeamHomePage Error:', error);
  }, []);

  if (contextLoadingStatus === LoadingStatus.Loading) {
    return <LoadingSpinner data-testid="loading-spinner" />;
  }

  if (contextLoadingStatus === LoadingStatus.Failed) {
    return <div data-testid="loading-error">{CONSTANTS.ERROR_MESSAGES.LOADING_ERROR}</div>;
  }

  const TeamHeader = () => (
    <Header
      variant="h1"
      info={
        <Link variant="info" onFollow={() => setToolsOpen(true)} data-testid="info-link">
          Info
        </Link>
      }
      actions={
        <SpaceBetween size="m" direction="horizontal">
          <ResetButton onReset={() => resetRef.current?.()}>Reset to default layout</ResetButton>
        </SpaceBetween>
      }
    >
      {CONSTANTS.HEADER.TITLE}
    </Header>
  );

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={handleError} data-testid="fintech-error-boundary">
      <AppLayout
        ref={appLayoutRef}
        headerSelector="#h"
        contentType="dashboard"
        ariaLabels={appLayoutAriaLabels}
        navigation={<DFPHomePageSideNavigation />}
        stickyNotifications
        notifications={<Flashbar items={flashbarItems} />}
        tools={<InfoPanelDetailsHelpPanel headerName={CONSTANTS.HEADER.INFO_TITLE} sidePanelDetails={teamPageContent.sidePanelDetails} />}
        toolsOpen={toolsOpen}
        onToolsChange={({ detail }) => setToolsOpen(detail.open)}
        maxContentWidth={Number.MAX_VALUE}
        disableContentPaddings
        content={
          <div className="app-layout-body" data-testid="app-layout-body">
            <ContentLayout defaultPadding header={<TeamHeader />}>
              {teamPageContent.enableTeamInfoTabs && teamPageContent.teams && (
                <TeamTabs teams={teamPageContent.teams} onError={handleError} resetRef={resetRef} data-testid="team-tabs" />
              )}
            </ContentLayout>
          </div>
        }
        data-testid="fintech-app-layout"
      />
    </ErrorBoundary>
  );
};
