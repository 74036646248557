export const CONSTANTS = {
  HEADER: {
    TITLE: 'DaS FinTech Team',
    INFO_TITLE: 'Team Information'
  },
  ERROR_MESSAGES: {
    LOADING_ERROR: 'Error loading content',
    GENERIC_ERROR: 'An error occurred while loading the page.',
    NO_TEAMS: 'No teams available',
    NO_CONTENT: 'No content available',
    LOAD_CONTENT_ERROR: 'Unable to load team content.'
  },
  EMPTY_STATES: {
    NO_TEAMS: {
      TITLE: 'No teams available',
      DESCRIPTION: 'There are currently no teams to display.'
    },
    NO_CONTENT: {
      TITLE: 'No content available',
      DESCRIPTION: 'Unable to load team content.'
    },
    NO_WIDGETS: {
      TITLE: 'No team contents',
      DESCRIPTION: 'There are no widgets on this page.'
    }
  },
  BOARD: {
    SECTIONS: {
      RESOURCES: 'Resources',
      TEAM_INTRO: 'Intro to the team'
    }
  },
  ACCESSIBILITY: {
    VIDEO_PLAYER: {
      PRESENTATION_LABEL: (teamName: string) => `Video presentation for ${teamName}`
    }
  }
};
