import React, { useState, useRef, useCallback, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Tabs } from '@amzn/awsui-components-react';
import { TeamTabContent } from './TeamTabContent';
import { CONSTANTS } from '../constants';
import { TeamEntity } from '../../admin-components/teams-manage/TeamManagementModel';
import { ErrorFallback } from 'src/components/generic-components/ErrorFallback';

interface TeamTabsProps {
  teams: TeamEntity[];
  onError: (error: Error) => void;
  resetRef: React.MutableRefObject<(() => void) | undefined>;
}

/**
 * TeamTabs component that manages multiple team tabs and their content
 */
export const TeamTabs: React.FC<TeamTabsProps> = ({ teams, onError, resetRef }) => {
  const [activeTabId, setActiveTabId] = useState<string>(teams[0]?.id || '');

  // Show message if no teams are available
  if (!teams.length) {
    return <div data-testid="no-teams-message">{CONSTANTS.ERROR_MESSAGES.NO_TEAMS}</div>;
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={onError} data-testid="team-tabs-error-boundary">
      <Tabs
        ariaLabel="Team information tabs"
        activeTabId={activeTabId}
        onChange={({ detail }) => setActiveTabId(detail.activeTabId)}
        tabs={teams.map((team) => ({
          id: team.id,
          label: team.teamName,
          content: (
            <TeamTabContent key={team.id} fintechTeam={team} onError={onError} resetRef={resetRef} data-testid={`team-tab-content-${team.id}`} />
          )
        }))}
        data-testid="team-tabs"
      />
    </ErrorBoundary>
  );
};
