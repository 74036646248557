import { Box, Button, Container, FormField, Header, Input, SpaceBetween } from '@amzn/awsui-components-react';
import { FormikProps } from 'formik';
import React, { useState, useCallback } from 'react';
import { TeamPageWizard, TeamBasicInfoWizard, TeamEntity } from '../TeamManagementModel';
import { v4 as uuidv4 } from 'uuid';

interface TeamPageStep1Props {
  formik: FormikProps<TeamPageWizard>;
}

export const TeamPageStep1: React.FC<TeamPageStep1Props> = ({ formik }) => {
  const [editingIndex, setEditingIndex] = useState<number | null>(null);

  const renderHeader = () => (
    <Header variant="h2" description="Teams cannot be deleted after changes are submitted. For assistance with removal, submit a support ticket.">
      Set up and edit team names
    </Header>
  );

  const getFieldError = useCallback(
    (fieldName: string, index: number): string | undefined => {
      const paths = [`teamManagement[${index}].${fieldName}`];

      for (const path of paths) {
        const touchedField = formik.getFieldMeta(path).touched;
        const errorField = formik.getFieldMeta(path).error;
        if (touchedField && errorField) {
          return errorField;
        }
      }

      return undefined;
    },
    [formik]
  );

  const handleEdit = (index: number) => {
    setEditingIndex(index);
    formik.setFieldTouched(`teamManagement[${index}].teamName`, true);
    formik.setFieldTouched(`teamPageDetailsAndWidgets.teams[${index}].teamName`, true);
  };

  const handleCancel = () => {
    if (editingIndex !== null) {
      // Reset the field value to its initial state
      formik.setFieldValue(`teamManagement[${editingIndex}].teamName`, formik.initialValues.teamManagement[editingIndex].teamName);
      formik.setFieldValue(
        `teamPageDetailsAndWidgets.teams[${editingIndex}].teamName`,
        formik.initialValues.teamPageDetailsAndWidgets.teams[editingIndex].teamName
      );

      // Clear touched state
      formik.setFieldTouched(`teamManagement[${editingIndex}].teamName`, false);
      formik.setFieldTouched(`teamPageDetailsAndWidgets.teams[${editingIndex}].teamName`, false);

      // Clear errors
      formik.setFieldError(`teamManagement[${editingIndex}].teamName`, undefined);
      formik.setFieldError(`teamPageDetailsAndWidgets.teams[${editingIndex}].teamName`, undefined);

      // Exit edit mode
      setEditingIndex(null);
    }
  };

  const handleSave = (index: number) => {
    formik.setFieldTouched(`teamManagement[${index}].teamName`, true);
    formik.setFieldTouched(`teamPageDetailsAndWidgets.teams[${index}].teamName`, true);
    formik.validateField(`teamManagement[${index}].teamName`);
    formik.validateField(`teamPageDetailsAndWidgets.teams[${index}].teamName`);

    if (!getFieldError('teamName', index)) {
      setEditingIndex(null);
    }
  };

  const handleBlur = (index: number) => {
    const trimmedValue = formik.values.teamManagement[index].teamName.trim();
    formik.setFieldValue(`teamManagement[${index}].teamName`, trimmedValue);
    formik.setFieldValue(`teamPageDetailsAndWidgets.teams[${index}].teamName`, trimmedValue);
    formik.setFieldTouched(`teamManagement[${index}].teamName`, true);
    formik.setFieldTouched(`teamPageDetailsAndWidgets.teams[${index}].teamName`, true);
    formik.validateField(`teamManagement[${index}].teamName`);
    formik.validateField(`teamPageDetailsAndWidgets.teams[${index}].teamName`);
  };

  const handleAddNewTeam = () => {
    const uniqueId = uuidv4();
    const newTeam: TeamBasicInfoWizard = {
      id: uniqueId,
      isNewTeam: true,
      teamName: ''
    };

    const newTeamEntity: TeamEntity = {
      id: uniqueId,
      teamName: '',
      teamIntroductionVideoUrl: '',
      teamOverView: '',
      teamWelcomeDetails: []
    };

    formik.setValues({
      ...formik.values,
      teamManagement: [...formik.values.teamManagement, newTeam],
      teamPageDetailsAndWidgets: {
        ...formik.values.teamPageDetailsAndWidgets,
        teams: [...formik.values.teamPageDetailsAndWidgets.teams, newTeamEntity]
      }
    });

    formik.setFieldTouched('teamManagement', true);
  };

  const handleRemoveNewTeam = (index: number) => {
    const updatedTeams = formik.values.teamManagement.filter((_, i) => i !== index);
    formik.setFieldValue('teamManagement', updatedTeams);
    formik.setFieldValue('teamPageDetailsAndWidgets.teams', updatedTeams);
  };

  return (
    <SpaceBetween size="m" direction="vertical">
      <Container variant="stacked" header={renderHeader()}>
        <Box variant="awsui-key-label" padding={{ bottom: 's' }}>
          {'Team name'}
        </Box>
        <SpaceBetween size="l" direction="vertical">
          {formik.values.teamManagement.map((team, index) => {
            const isEditing = editingIndex === index || team.isNewTeam;
            const error = getFieldError('teamName', index);
            return (
              <SpaceBetween size="m" direction="horizontal" key={team.id}>
                <FormField className="width-25rem" errorText={error}>
                  <Input
                    value={team.teamName || ''}
                    onChange={(e) => {
                      formik.setFieldValue(`teamManagement[${index}].teamName`, e.detail.value);
                      formik.setFieldValue(`teamPageDetailsAndWidgets.teams[${index}].teamName`, e.detail.value);
                    }}
                    onBlur={() => handleBlur(index)}
                    readOnly={!isEditing}
                  />
                </FormField>

                {team.isNewTeam ? (
                  <Button onClick={() => handleRemoveNewTeam(index)}>Remove</Button>
                ) : !isEditing ? (
                  <Button onClick={() => handleEdit(index)}>Edit</Button>
                ) : (
                  <>
                    <Button iconName="close" onClick={handleCancel} />
                    <Button iconName="check" onClick={() => handleSave(index)} disabled={!!error || !team.teamName.trim()} />
                  </>
                )}
              </SpaceBetween>
            );
          })}
          <Button onClick={handleAddNewTeam}>Add new team</Button>
        </SpaceBetween>
      </Container>
    </SpaceBetween>
  );
};
