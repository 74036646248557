import { Link } from '@amzn/awsui-components-react';
import React from 'react';
import { transformBroadcastUrl } from './utils/BroadcastUrlUtils';
import { logger } from 'src/logger';

interface BroadcastVideoLinkProps {
  link: string;
  title: string;
  className?: string;
}

/**
 * A component that renders a link to a broadcast video.
 * The link opens in a new tab and includes accessibility features.
 *
 * Note: URLs are pre-validated through Yup schema during tutorial creation/edit
 *
 * @param {Object} props - The component props
 * @param {string} props.link - The video link URL to be transformed
 * @param {string} props.title - The display text and aria label for the link
 * @param {string} [props.className] - Optional CSS class name
 * @returns {JSX.Element} A Link component configured for broadcast video
 */
export const BroadcastVideoLink: React.FC<BroadcastVideoLinkProps> = ({ link, title, className }) => {
  try {
    const videoUrl = transformBroadcastUrl.toVideo(link);

    return (
      <Link
        className={className}
        variant="secondary"
        external
        href={videoUrl}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={`${title} (opens in new tab)`}
        data-testid="broadcast-video-link"
      >
        {title}
      </Link>
    );
  } catch (error) {
    logger.error('Error transforming broadcast URL', { link, error });
    return null;
  }
};
