import { html, internalProperty, property } from 'lit-element';
import { nothing } from 'lit-html';
import { classMap } from 'lit-html/directives/class-map';
import { ifDefined } from 'lit-html/directives/if-defined';
import {
  event,
  EventEmitter,
  KatLitElement,
  Keys,
  register,
} from '../../shared/base';
import baseStyles from '../../shared/base/base.lit.scss';
import { checkSlots } from '../../shared/slot-utils';
import { isSafari } from '../../shared/utils';
import { formInputMap } from '../../utils/form-input-map';

import {
  KatFileItemUpdateState,
  KatFileItemUploadState,
  KatFileUploadState,
  KatFileUploadStatus,
  KatFileUploadVariant,
  KatFileUploadView,
} from './file-upload-defs';
import getString from './strings';
import styles from './file-upload.lit.scss';

type ModalTitleKey =
  | 'kat_title_delete_this_file'
  | 'kat_title_delete_all_files'
  | 'kat_title_replace_this_file'
  | 'kat_title_replace_all_files';

type ModalMessageKey =
  | 'kat_file_will_be_deleted'
  | 'kat_files_will_be_deleted'
  | 'kat_file_will_be_replaced'
  | 'kat_files_will_be_replaced';

type ModalActionKey = 'kat_label_delete' | 'kat_label_replace';

interface KatFileUploadModalConfig {
  title_key: ModalTitleKey;
  message_key: ModalMessageKey;
  action_key: ModalActionKey;
  files: File[];
  randomizer: number;
}

/**
 * @component {kat-file-upload} KatalFileUpload The File Upload component allows our users to upload a single or multiple files, with options for auto-upload and file preview. It can be used in forms or as a standalone component. Files may be added by clicking a button or dragging and dropping into the target area.
 * @guideline Do Provide clear and flexible file requirements. Overly restrictive requirements for file format and file size are frustrating for users.
 * @guideline Do Provide meaningful error messages, and allow users to attempt to fix failed uploads.
 * @guideline Do Use consistent language - users "upload" files when they are automatically uploaded. Use "select" when the files will not be uploaded until later (e.g., as part of a form).
 * @guideline Do Use “files” (plural) only when the other may select multiple files. Otherwise, use the singular "file".
 * @guideline Dont Avoid using in modals due to space constraints.
 * @guideline Dont Don’t display file previews or thumbnails if it does not help the end-user identify the file contents.
 * @status Production
 * @theme flo
 * @slot label Static slot. Content to be displayed on top of the component. Defaults to empty.
 * @slot hint Static slot. Blurb to display in the component. Defaults to "Drag file here to attach".
 * @slot error Static slot. Blurb to display as error message, when the component is set to error state. Defaults to "File upload was unsuccessful".
 * @slot constraints-message Static slot. Blurb to display as attachment constraints message (E.G. 500KB max). Defaults to empty.
 * @slot file-thumbnail-FILE_NAME Dynamic slot. Use to provide thumbnail content for the attached file that has the name FILE_NAME. Defaults to "insert_drive_file" icon.
 * @slot file-error-summary-FILE_NAME Dynamic slot. Use to provide a custom error summary message for the file’s upload process that has the name FILE_NAME. Defaults to generic "File not uploaded" message. The summary line will be ellipsised if too long.
 * @slot file-error-detail-FILE_NAME Dynamic slot. Use to provide a custom error detail message for the file’s upload process that has the name FILE_NAME. Defaults to generic "File not uploaded" message. The detail will be displayed in a popover.
 * @example SmallListSingle {"variant":"small","file-view":"list"}
 * @example SmallListPreviewSingle {"variant":"small","file-view":"list-preview"}
 * @example SmallGridMultiple {"variant":"small","file-view":"grid","multiple":"true"}
 * @example SmallGrid4ColumnsMultiple {"variant":"small","file-view":"grid", "max-files-per-grid-row":4,"multiple":"true"}
 * @example LargeList {"variant":"large","file-view":"list"}
 * @example LargeListPreview {"variant":"large","file-view":"list-preview"}
 * @example LargeGridMultiple {"variant":"large","file-view":"grid","multiple":"true"}
 * @example LargeGrid6ColumnsMultiple {"variant":"large","file-view":"grid", "max-files-per-grid-row":6,"multiple":"true"}
 * @example SmallListInFormMultiple {"variant":"small","file-view":"list","name":"form-attachment","multiple":"true"}
 * @example RequiredAndChecksMaxSize {"variant":"small","file-view":"list","required":"true","max-size":"512000"}
 * @example RequiredAndAcceptsPNG {"variant":"small","file-view":"list","required":"true","accept":"image/png"}
 * @example SmallListSingleDisabled {"variant":"small","file-view":"list","disabled":"true"}
 * @example SmallListSingleError {"variant":"small","file-view":"list","state":{"details": {"status":"error"}}}
 * @example UsingSlots {"variant":"small","file-view":"list", "content":"
 *    <div slot=\"hint\">This is a hint</div>
 *    <div slot=\"label\">File upload label</div>
 *    <div slot=\"error\">Generic error happened</div>
 *    <div slot=\"constraints-message\">
 *        Format: JPG, PNG, TGA, TIF, GIF<br />500kb max file size
 *    </div>
 * "}
 * @example InitialFiles {
 *   "multiple": "true",
 *   "script": "
 *     const mockFiles = [
 *       {
 *         file: new File(['mockfilecontents'], 'mock-file-one.txt', {
 *          type: 'text/plain',
 *         }),
 *         status: 'ready',
 *       },
 *       {
 *         file: new File(['mockfilecontents'], 'mock-file-two.txt', {
 *           type: 'text/plain',
 *         }),
 *         status: 'uploading',
 *         percent: 50,
 *       },
 *       {
 *         file: new File(['mockfilecontents'], 'mock-file-three.txt', {
 *           type: 'text/plain',
 *         }),
 *         status: 'error',
 *         errorSummary: 'It is no good!',
 *       },
 *       {
 *         file: new File(['mockfilecontents'], 'mock-file-four.txt', {
 *           type: 'text/plain',
 *         }),
 *         status: 'complete',
 *       }
 *     ];
 *     const upload = document.querySelector('kat-file-upload');
 *     upload.files = mockFiles;
 *   "}
 * @a11y {keyboard}
 * @a11y {contrast}
 * @a11y {sr}
 */
@register('kat-file-upload')
export class KatFileUpload extends KatLitElement {
  // The next three jsdoc comments are required for react wrapper code to generate object type definitions.
  /**
   * @typedef {Object} KatFileItemUploadState
   * @type {object}
   * @property {KatFileUploadStatus} status Required. The current file status.
   * @property {File} file Required. The file object itself.
   * @property {string} preview Optional. String representing the URL or base64 data of this file preview.
   * @property {string} percent Optional. Number between 0 and 100 indicating the file upload progress.
   * @property {string} errorSummary Optional. The error summary of the upload process if it errored.
   * @property {string} errorDetail Optional. The error detail of the upload process if it errored.
   */
  /**
   * @typedef {Object} KatFileItemUpdateState
   * @type {object}
   * @property {KatFileUploadStatus} status Required. The current file status.
   * @property {string} name Required. The name of the file that's being updated.
   * @property {string} preview Optional. String representing the URL or base64 data of this file preview.
   * @property {string} errorSummary Optional. The error summary of the upload process if it errored.
   * @property {string} errorDetail Optional. The error detail of the upload process if it errored.
   */
  /**
   * @typedef {Object} KatFileUploadState
   * @type {object}
   * @property {KatFileUploadStatus} status Required. The overall file upload status.
   * @property {string} error Optional. The overall error message, if any.
   */
  /**
   * @classprop {enum} katFileUploadStatus @hidden
   * @enum {value} ready File is ready to be uploaded.
   * @enum {value} uploading File is in the process of uploading.
   * @enum {value} complete File upload completed successfully.
   * @enum {value} error File upload had errors.
   * @private
   */

  /**
   * If specified, this is the name assigned to the input that will hold attachment
   * values to be submitted when this file upload is used in a HTML multipart form.
   * @type {string}
   */
  @property()
  name?: string;

  /**
   * If true, indicates that attaching file is required for the process to continue.
   * Deleting all files triggers an error.
   */
  @property()
  required? = false;

  /**
   * If true, sets the component to accept multiple files.
   */
  @property()
  multiple? = false;

  /**
   * If true, hides the clear-all button.
   */
  @property({ attribute: 'hide-clear-all' })
  hideClearAll? = false;

  /**
   * The maximum size in bytes allowed to be attached/uploaded per file. Zero or negative values mean unlimited.
   * @type {number}
   */
  @property({ attribute: 'max-size' })
  maxSize?: number;

  /**
   * This is the same attribute as per the accept attribute of HTML <input type=file> element.
   * Specifies the types of files to allow for selection from the dialog or drag drop.
   * @type {string}
   */
  @property()
  accept?: string;

  /**
   * Locale to identify the language used in fixed labels.
   * Use this property/attribute to override the automatic language detection.
   * @type {string}
   */
  @property()
  locale?: string;

  /**
   * Set to true to disable UI interaction with file upload component.
   * This flag has no effect on programmatic interaction.
   * @type {boolean}
   */
  @property()
  disabled?: boolean;

  /**
   * Rendering mode of the attached file(s) list.
   * @enum {value} list Renders attached files in a list below the attachment area.
   * @enum {value} list-preview Renders attached files in a list below the attachment area with attachment preview.
   * @enum {value} grid Renders attached files as a grid below the attachment area with larger attachment preview.
   */
  @property({
    attribute: 'file-view',
    reflect: true,
    converter: {
      fromAttribute: value =>
        KatFileUploadView[value?.toUpperCase().replace('-', '_')] ||
        KatFileUploadView.LIST,
      toAttribute: value => value,
    },
  })
  fileView?: KatFileUploadView = KatFileUploadView.LIST;

  /**
   * The number of files per grid row when the file-view is set to grid.
   * Valid range is from 2 to 6.
   * Defaults to 3.
   * @type {number}
   */
  @property({
    attribute: 'max-files-per-grid-row',
    reflect: true,
    converter: {
      fromAttribute: value => Math.max(2, Math.min(parseInt(value), 6)),
      toAttribute: value => value,
    },
  })
  maxFilesPerGridRow?: number = 3;

  /**
   * Rendering mode of the attached file(s) list.
   * @enum {value} small Renders small variant of the drag-drop zone.
   * @enum {value} large Renders large variant of the drag-drop zone.
   */
  @property({
    reflect: true,
    converter: {
      fromAttribute: value =>
        KatFileUploadVariant[value?.toUpperCase()] ||
        KatFileUploadVariant.SMALL,
      toAttribute: value => value,
    },
  })
  variant?: KatFileUploadVariant = KatFileUploadVariant.SMALL;

  /**
   * A set-only property to update file upload state as upload process progresses.
   * Has the format {details:{status:KatFileUploadStatus, error:string}}, where KatFileUploadStatus is enum of ready | uploading | complete | error
   */
  @property()
  state?: { fileUploadState: KatFileUploadState }; // An object wrapper is necessary for doc site to properly render the prop.

  /**
   * A set-only property to update individual file(s) when its(their) state(s) change(s).
   * Has the format {items:[{name: string, status: KatFileUploadStatus, preview?: string, errorSummary?: string, errorDetail?: string}]},
   * where KatFileUploadStatus is enum of ready | uploading | complete | error
   */
  @property({ attribute: 'file-states' })
  fileStates?: { fileItemUpdateStates: KatFileItemUpdateState[] }; // An object wrapper is necessary for doc site to properly render the prop.

  /**
   * An array of FileItemUploadStates. Displays a KatFileItem for each entry.
   * Updates to this property will not trigger the `filesAttached`, `filesRemoved`, or `filesReplaced` events.
   *
   * This property is immutable. In order to reflect updates, the object reference must be updated.
   */
  @property()
  files?: KatFileItemUploadState[];

  /**
   * Sets to true if there has been an error in the file uploading process.
   * @private
   */
  @internalProperty()
  private _state: KatFileUploadState = {
    status: KatFileUploadStatus.READY,
  };

  /**
   * Property to hold the list of currently attached files.
   * @private
   */
  @internalProperty()
  private _fileItems: Map<string, KatFileItemUploadState> = new Map<
    string,
    KatFileItemUploadState
  >();

  /**
   * Property to configure modal message when user attempts to delete or replace
   * one or more files.
   * @private
   */
  @internalProperty()
  private _modalConfig?: KatFileUploadModalConfig;

  /**
   * Fires when files are selected by the user.
   */
  @event('filesAttached', false)
  filesAttached: EventEmitter<{ files: File[] }>;

  /**
   * Fires when files are removed by the user.
   */
  @event('filesRemoved', false)
  filesRemoved: EventEmitter<{ files: File[] }>;

  /**
   * Fires when files are removed by the user.
   */
  @event('filesReplaced', false)
  filesReplaced: EventEmitter<{ oldFiles: File[]; newFiles: File[] }>;

  /**
   * Fires when there is an error in newly attached files. E.G. duplicate file name
   */
  @event('filesAttachedError', false)
  filesAttachedError: EventEmitter<{
    error: string;
    files: KatFileItemUploadState[];
  }>;

  static get styles() {
    return [baseStyles, styles];
  }

  // This trivial initializer is necessary to be able to mock the props of the returned object,
  // otherwise we are unable to globally mock the whole class.
  private _createDataTransfer() {
    return new DataTransfer();
  }

  update(changedProperties) {
    if (changedProperties.has('state')) {
      this._state = {
        status:
          KatFileUploadStatus[
            this.state?.fileUploadState.status.toUpperCase()
          ] ?? KatFileUploadStatus.READY,
        error: this.state?.fileUploadState.error,
      };
    }

    if (changedProperties.has('files')) {
      // Reduces need for consumers to pass duplicative data, as _fileItems is keyed by file name, which is already a property of the File object.
      this.files.forEach(file => this._fileItems.set(file.file.name, file));
    }

    if (changedProperties.has('_fileItems')) {
      this.files = [...this._fileItems.values()];
    }

    if (changedProperties.has('fileStates')) {
      this.fileStates?.fileItemUpdateStates?.forEach(item => {
        const fileItem = this._fileItems.get(item.name);
        if (fileItem) {
          fileItem.status = item.status;
          fileItem.preview = item.preview;
          fileItem.percent = item.percent;
          fileItem.errorDetail = item.errorDetail;
          fileItem.errorSummary = item.errorSummary;
        }
      });
    }

    super.update(changedProperties);
  }

  @formInputMap([
    {
      tag: 'input',
      name: (component: KatFileUpload) => component.name,
      isNeeded: (component: KatFileUpload) => component.isFormInputNeeded(),
      setup: (component: KatFileUpload, input: HTMLInputElement) =>
        component.setupFormInput(input),
    },
  ])
  updated(changedProperties: Map<string, any>) {
    super.updated(changedProperties);
  }

  isFormInputNeeded() {
    return !(this.disabled || !this.name);
  }

  setupFormInput(input: HTMLInputElement) {
    try {
      input.setAttribute('type', 'file');
      this.multiple && input.setAttribute('multiple', 'true');

      const dataTransferAPI = this._createDataTransfer();
      this._fileItems.forEach(item => dataTransferAPI.items.add(item.file));
      input.files = dataTransferAPI.files;
    } catch (_) {
      // discard. This only happens in tests where File objects are mocked.
      // mocked objects cannot be added to data transfer API
      // due to inherent security limitations imposed on File and attachments in JS
    }
  }

  /**
   * Call this at @slotchange on a slot tag
   * @private
   */
  private _childrenChanged() {
    this.requestUpdate();
  }

  private get _plurality(): string {
    return this.multiple ? 's' : '';
  }

  private get _uploadFileLabel(): string {
    return getString(
      `kat_upload_file${this._plurality}` as any, // as any is required to bypass type enforcement error of lengthy enum values
      null,
      this.locale
    );
  }

  private get _hintLabel(): string {
    return getString(
      `kat_drag_file${this._plurality}_here_to_upload` as any, // as any is required to bypass type enforcement error of lengthy enum values
      null,
      this.locale
    );
  }

  private get _isRequiredViolated(): boolean {
    return this.required && this._fileItems.size < 1;
  }

  private get _isMaxSizeViolated(): boolean {
    return this.maxSize && this.maxSize > 0 && this._hasTooLargeFile;
  }

  private get _isFileFormatViolated(): boolean {
    if (!this.accept) {
      return false;
    }

    // Short-circuit if we find a violation
    return [...this._fileItems.values()].some(item =>
      this._doesFileViolateFormat(item.file)
    );
  }

  private _isDuplicateNameViolated(file: File): boolean {
    return !!this._fileItems.get(file!.name);
  }

  private get _hasTooLargeFile(): boolean {
    return Array.from(this._fileItems).some(
      item => item[1].file.size > this.maxSize
    );
  }

  private _isFileTooLarge(file: File): boolean {
    return this.maxSize && this.maxSize > 0 && file.size > this.maxSize;
  }

  private _doesFileViolateFormat(file: File): boolean {
    if (!this.accept) {
      return false;
    }

    const allowedFileFormats = this.accept
      .split(',')
      .map(acceptOption => acceptOption.trim());
    const isFileMIMETypeAccepted = () => {
      const isExactMatch = allowedFileFormats.includes(file.type);
      const isWildcardMatch = allowedFileFormats.includes(
        `${file.type.split('/').shift()}/*`
      );
      return isExactMatch || isWildcardMatch;
    };

    const isFileExtensionAccepted = () => {
      // allow either `jpg` or `.jpg` as a format for file extension
      // only `.jpg` matches the HTML spec to provide built-in browser validation
      // `jpg` format is left to prevent breaking changes.
      const fileExtension = file.name.split('.').pop();
      const fileExtensionWithDot = `.${fileExtension}`;
      return (
        allowedFileFormats.includes(fileExtension) ||
        allowedFileFormats.includes(fileExtensionWithDot)
      );
    };

    return !isFileMIMETypeAccepted() && !isFileExtensionAccepted();
  }

  private _isButtonAction = event =>
    !event.keyCode || Keys.Confirm.includes(event.keyCode);

  private _onFileDragOver = event => {
    event.preventDefault();

    this.__isDragging = true;

    const overlay = this.shadowRoot.querySelector('.file-upload-input-overlay');
    const selectFile = this.shadowRoot.querySelector('#select-file');
    overlay.classList.add('hover');
    selectFile.classList.add('standby');
  };

  private _onFileDragOut = event => {
    event.preventDefault();

    this.__isDragging = false;

    const overlay = this.shadowRoot.querySelector('.file-upload-input-overlay');
    const selectFile = this.shadowRoot.querySelector('#select-file');
    overlay.classList.remove('hover');
    selectFile.classList.remove('standby');
  };

  /*
   Below function is for safari specifically.
   Overriding title attribute on input type=file has no effect in safari
   https://stackoverflow.com/questions/12035400/how-can-i-remove-the-no-file-chosen-tooltip-from-a-file-input-in-chrome
   Safari force enables the system tooltip at browser level.
   This requires a hacky solution to obscure the input type=file when mouse hovering it in Safari.
   For all other browsers setting <input title=""> empty string disables the tooltip.
   */
  private __isDragging = false;
  private _setInputDisabled = (event, disabled) => {
    event.preventDefault();
    event.stopImmediatePropagation();

    const overlay = this.shadowRoot.querySelector(
      '.file-upload-input-overlay'
    ) as HTMLInputElement;

    if (isSafari() && !this.disabled && !this.__isDragging) {
      overlay.style.pointerEvents = disabled ? 'all' : 'none';
    }
  };

  private _onBrowse = event => {
    event.preventDefault();

    if (this._isButtonAction(event)) {
      const input: HTMLElement = this.shadowRoot.querySelector(
        '#kat-file-attachment'
      );
      input.click();
    }
  };

  private _onAllFilesRemoved = event => {
    event.preventDefault();

    this._onConfirm(
      'delete',
      Array.from(this._fileItems).map(item => item[1].file)
    );
  };

  private _onFilesRemoved = event => {
    event.preventDefault();

    this._onConfirm(
      'delete',
      Array.from(this._fileItems)
        .filter(item => item[0] === event.detail.name)
        .map(item => item[1].file)
    );
  };

  private _onFilesAttached = event => {
    event.preventDefault();
    this._onFileDragOut(event);

    const input: HTMLInputElement = this.shadowRoot.querySelector(
      '#kat-file-attachment'
    );

    const { newFiles, duplicateFiles } = this._analyzeAttachments(
      Array.from(input.files)
    );

    if (this.multiple) {
      if (newFiles.length > 0) {
        newFiles.forEach(file =>
          this._fileItems.set(file.name, {
            file,
            status: KatFileUploadStatus.READY,
          })
        );
        this._validate();
        this.filesAttached.emit({ files: newFiles });
      }

      if (duplicateFiles.length > 0) {
        this._onConfirm('replace', duplicateFiles);
      }
    } else {
      const allFiles = [...newFiles, ...duplicateFiles];
      if (this._fileItems.size > 0) {
        this._onConfirm('replace', allFiles);
      } else {
        allFiles.forEach(file =>
          this._fileItems.set(file.name, {
            file,
            status: KatFileUploadStatus.READY,
          })
        );
        this._validate();
        this.filesAttached.emit({ files: allFiles });
      }
    }

    input.value = '';

    this.requestUpdate();
  };

  private _onConfirm = (action: 'delete' | 'replace', files: File[]) => {
    const count = files.length > 1 ? 'all' : 'this';
    const plural = files.length > 1 ? 's' : '';

    const title_key =
      `kat_title_${action}_${count}_file${plural}` as ModalTitleKey;
    const message_key =
      `kat_file${plural}_will_be_${action}d` as ModalMessageKey;
    const action_key = `kat_label_${action}` as ModalActionKey;
    this._modalConfig = {
      title_key,
      message_key,
      action_key,
      files,
      // This is required
      // Introduce a content variation to trigger the `update` callback
      randomizer: Math.random(),
    };
  };

  private _onModalCancel = event => {
    event.preventDefault();

    if (this._isButtonAction(event)) {
      this._modalConfig = null;
      this.requestUpdate();
    }
  };

  private _onModalConfirm = event => {
    event.preventDefault();

    if (!this._isButtonAction(event)) {
      return;
    }

    const input: HTMLInputElement = this.shadowRoot.querySelector(
      '#kat-file-attachment'
    );

    const fileKeys = this._modalConfig.files.map(file => file.name);

    if (this._modalConfig.action_key === 'kat_label_delete') {
      this._modalConfig.files.forEach(file =>
        this._fileItems.delete(file.name)
      );

      this._validate();
      this.filesRemoved.emit({ files: this._modalConfig.files });

      this._modalConfig.files.length > 1 && (input.value = '');
    } else {
      const oldFiles: File[] = Array.from(this._fileItems)
        .filter(item => fileKeys.includes(item[0]))
        .map(item => item[1].file);
      const newFiles = this._modalConfig.files;

      !this.multiple && this._fileItems.clear();

      this._modalConfig.files.forEach(file =>
        this._fileItems.set(file.name, {
          file,
          status: KatFileUploadStatus.READY,
        })
      );
      this._validate();
      this.filesReplaced.emit({ oldFiles, newFiles });
    }

    this._onModalCancel(event); // reset and dismiss once handled
  };

  private _analyzeAttachments(files: File[]) {
    const newFiles: File[] = [];
    const duplicateFiles: File[] = [];

    files.forEach(file => {
      // Checks for duplicate names.
      // Uploading files with duplicate names is not allowed as it will create ambiguous upload state.
      if (this._isDuplicateNameViolated(file)) {
        duplicateFiles.push(file);
      } else {
        newFiles.push(file);
      }
    });

    return { newFiles, duplicateFiles };
  }

  private _validate() {
    let error: string;
    const badFiles: KatFileItemUploadState[] = [];

    const requiredViolated = this._isRequiredViolated;
    const maxSizeViolated = this._isMaxSizeViolated;
    const fileFormatViolated = this._isFileFormatViolated;

    requiredViolated && (error = 'kat_attachment_required');
    fileFormatViolated && (error = error || 'kat_unsupported_file_attached');
    maxSizeViolated && (error = error || 'kat_over_max_size');

    if (fileFormatViolated || maxSizeViolated) {
      const msgUnsupportedFile = getString(
        'kat_unsupported_file_attached',
        null,
        this.locale
      );
      const msgTooBigFile = getString('kat_over_max_size', null, this.locale);

      this._fileItems.forEach(item => {
        const isTooLarge = this._isFileTooLarge(item.file);
        const isViolatedFormat = this._doesFileViolateFormat(item.file);

        if (isViolatedFormat || isTooLarge) {
          const errorSummary = isViolatedFormat
            ? msgUnsupportedFile
            : msgTooBigFile;
          const errorDetail = `${isViolatedFormat ? msgUnsupportedFile : ''}\n${
            isTooLarge ? msgTooBigFile : ''
          }`;

          item.status = KatFileUploadStatus.ERROR;
          item.errorSummary = errorSummary;
          item.errorDetail = errorDetail;
          badFiles.push(item);
        }
      });
    }

    if (error) {
      this._state = {
        status: KatFileUploadStatus.ERROR,
        error: getString(error as any, null, this.locale),
      };
      this.filesAttachedError.emit({ error, files: badFiles });
    } else {
      this._state = {
        status: KatFileUploadStatus.READY,
      };
    }
  }

  private _renderUploadIcon(variant: KatFileUploadVariant) {
    const size =
      this.variant === KatFileUploadVariant.LARGE ? 'large' : 'small';
    return this.variant === variant
      ? html`<kat-icon
          part="file-upload-icon"
          name="file_upload"
          size=${size}
        ></kat-icon>`
      : nothing;
  }

  private _renderFileInput() {
    if (this.disabled) {
      return nothing;
    }

    // title attribute is intentionally blank in order to supress tooltip text on hover.
    return html`
      <input
        id="kat-file-attachment"
        part="file-upload-input"
        type="file"
        accept=${ifDefined(this.accept)}
        ?multiple=${this.multiple}
        @dragover=${this._onFileDragOver}
        @dragleave=${this._onFileDragOut}
        @change=${this._onFilesAttached}
        @mouseenter=${e => this._setInputDisabled(e, true)}
        aria-labelledby="select-file"
        title=""
      />
    `;
  }

  private _renderFileUploadArea() {
    const overlayClasses = {
      large: this.variant === KatFileUploadVariant.LARGE,
      disabled: this.disabled,
    };

    const uploadAreaClasses = {
      disabled: this.disabled,
      error: this._state.status === KatFileUploadStatus.ERROR,
    };

    return html`
      <div class="file-upload-area ${classMap(uploadAreaClasses)}">
        <div class="file-upload-input">
          <div class="file-upload-input-scaler">${this._renderFileInput()}</div>
        </div>
        <div
          class="file-upload-input-overlay ${classMap(overlayClasses)}"
          @mouseleave=${e => this._setInputDisabled(e, false)}
          @dragover=${this._discard}
          @dragleave=${this._discard}
          @drop=${this._discard}
        >
          ${this._renderUploadIcon(KatFileUploadVariant.LARGE)}
          <kat-button
            id="select-file"
            variant="secondary"
            size="base"
            part="file-upload-select-button"
            ?disabled=${this.disabled}
            @click=${this._onBrowse}
            ><div class="file-upload-button-label">
              ${this._renderUploadIcon(KatFileUploadVariant.SMALL)}
              ${this._uploadFileLabel}
            </div></kat-button
          >
          <div class="file-upload-hint">
            <slot name="hint" @slotchange=${this._childrenChanged}>
              ${this._hintLabel}
            </slot>
          </div>
        </div>
      </div>
    `;
  }

  private _discard = e => {
    e.preventDefault();
    e.stopImmediatePropagation();
  };

  private _renderFileView() {
    if (this._fileItems.size < 1) {
      return nothing;
    }

    const fileLayoutClasses = {
      ['two-column']:
        this.fileView === KatFileUploadView.GRID &&
        this.maxFilesPerGridRow === 2,
      ['four-column']:
        this.fileView === KatFileUploadView.GRID &&
        this.maxFilesPerGridRow === 4,
      ['five-column']:
        this.fileView === KatFileUploadView.GRID &&
        this.maxFilesPerGridRow === 5,
      ['six-column']:
        this.fileView === KatFileUploadView.GRID &&
        this.maxFilesPerGridRow === 6,
      ['file-upload-attachment-container']: true,
      [`file-upload-file-${this.fileView}`]: true,
    };

    return html`
      <div class="${classMap(fileLayoutClasses)}">
        ${Array.from(this._fileItems).map(fileItem =>
          this._renderFileItem(fileItem[1])
        )}
      </div>
    `;
  }

  private _renderFileItemImage(src: string) {
    return src
      ? html`<img src=${src} />`
      : html`<kat-icon name="insert_drive_file" size="small"></kat-icon>`;
  }

  private _renderFileItem(fileItem: KatFileItemUploadState) {
    const name = fileItem.file.name;
    const preview = this._renderFileItemImage(fileItem.preview);
    const errorSummary =
      fileItem.errorSummary ||
      getString('kat_file_not_uploaded', null, this.locale);
    const errorDetail =
      fileItem.errorDetail ||
      getString('kat_file_not_uploaded', null, this.locale);

    return html`
      <kat-file-item
        name=${name}
        locale=${this.locale}
        file-status=${fileItem.status}
        percent=${fileItem.percent}
        file-view=${this.fileView}
        part="attachment-${name}"
        @fileRemoved=${this._onFilesRemoved}
        ?disabled=${this.disabled}
      >
        <slot
          slot="thumbnail"
          name=${`file-thumbnail-${name}`}
          @slotchange=${this._childrenChanged}
          >${preview}</slot
        >
        <slot
          slot="error-summary"
          name=${`file-error-summary-${name}`}
          @slotchange=${this._childrenChanged}
          >${errorSummary}</slot
        >
        <slot
          slot="error-detail"
          name=${`file-error-detail-${name}`}
          @slotchange=${this._childrenChanged}
          >${errorDetail}</slot
        >
      </kat-file-item>
    `;
  }

  private _renderClearAll(variant: KatFileUploadVariant) {
    if (variant !== this.variant || this.hideClearAll) {
      return nothing;
    }

    const disabled = this.disabled || this._fileItems.size === 0;

    const buttonState = {
      disabled,
      large: this.variant === KatFileUploadVariant.LARGE,
    };

    return html`
      <button
        type="button"
        class="file-upload-clear-all ${classMap(buttonState)}"
        ?disabled=${disabled}
        @click=${this._onAllFilesRemoved}
      >
        ${getString('kat_clear_all_files', null, this.locale)}
      </button>
    `;
  }

  private _renderConfirmModal() {
    if (!this._modalConfig) {
      return nothing;
    }

    let fileNames: string;
    if (
      this._modalConfig.message_key === 'kat_file_will_be_replaced' &&
      !this.multiple
    ) {
      fileNames = Array.from(this._fileItems)[0][0];
    } else {
      fileNames = this._modalConfig.files.map(file => file.name).join(', ');
    }

    const title = getString(
      this._modalConfig.title_key,
      { number: `${this._modalConfig.files.length}` },
      this.locale
    );
    const message = getString(
      this._modalConfig.message_key,
      { file: `${fileNames}` },
      this.locale
    );
    const cancel = getString('kat_label_cancel', null, this.locale);
    const confirm = getString(this._modalConfig.action_key, null, this.locale);

    return html`
      <kat-modal
        id="kat-file-upload-confirm"
        @close=${this._onModalCancel}
        visible
      >
        <span slot="title">${title}</span>
        <p>${message}</p>
        <div
          slot="footer"
          class="kat-group-horizontal file-upload-modal-buttons"
        >
          <kat-button
            id="kat-file-upload-action-cancel"
            variant="secondary"
            part="file-upload-modal-cancel"
            @click=${this._onModalCancel}
            @keydown=${this._onModalCancel}
            >${cancel}</kat-button
          >
          <kat-button
            id="kat-file-upload-action-affirm"
            variant="primary"
            part="file-upload-modal-confirm"
            @click=${this._onModalConfirm}
            @keydown=${this._onModalConfirm}
            >${confirm}</kat-button
          >
        </div>
      </kat-modal>
    `;
  }

  /**
   * Removes a single file from the upload list. Does not trigger a confirmation modal. Does not trigger validation.
   * @param {String} key The name of the file to be cleared.
   * @returns {Promise<boolean>} The result of the clear operation.
   * @katalmethod
   */
  public async clearFile(key: string): Promise<boolean> {
    const result = this._fileItems.delete(key);
    await this.requestUpdate();

    return result;
  }

  /**
   * Clears all files from the upload list. Does not trigger a confirmation modal. Does not trigger validation.
   * @returns {Promise<void>}
   * @katalmethod
   */
  public async clearAllFiles(): Promise<void> {
    this._fileItems.clear();
    await this.requestUpdate();
  }

  render() {
    const slots = checkSlots(this);
    const labelCtaClasses = {
      hidden: !slots.label,
      disabled: this.disabled,
      error: this._state.status === KatFileUploadStatus.ERROR,
    };
    const constraintsCtaClasses = {
      hidden: !slots['constraints-message'],
      disabled: this.disabled,
    };
    const errorCtaClasses = {
      hidden: this._state.status !== KatFileUploadStatus.ERROR,
    };

    return html`
      <div class="file-upload">
        <div class="file-upload-label ${classMap(labelCtaClasses)}">
          <slot name="label" @slotchange=${this._childrenChanged}></slot>
        </div>
        ${this._renderFileUploadArea()}
        <div class="file-upload-blurbs-and-clear">
          <div class="file-upload-blurbs">
            <div class="file-upload-generic-error ${classMap(errorCtaClasses)}">
              <slot name="error" @slotchange=${this._childrenChanged}>
                ${this._state.error ??
                getString('kat_file_upload_unsuccessful', null, this.locale)}
              </slot>
            </div>
            <div
              class="file-upload-constraints ${classMap(constraintsCtaClasses)}"
            >
              <slot
                name="constraints-message"
                @slotchange=${this._childrenChanged}
              ></slot>
            </div>
          </div>
          ${this._renderClearAll(KatFileUploadVariant.LARGE)}
        </div>
        ${this._renderClearAll(KatFileUploadVariant.SMALL)}
        ${this._renderFileView()}
      </div>
      ${this._renderConfirmModal()}
    `;
  }
}
