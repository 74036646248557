import React, { DetailedHTMLProps, IframeHTMLAttributes, useMemo } from 'react';
import { Icon } from '@amzn/awsui-components-react';
import { VideoSource } from './types';
import { BROADCAST_VIDEO_CONSTANTS } from './constants';

interface BroadcastPlayerProps {
  videoSource: VideoSource;
  isPreview?: boolean;
}

/**
 * BroadcastPlayer component renders either a preview or full video player
 * for Amazon Broadcast videos. It handles both preview mode with a play button
 * and full player mode with complete video functionality.
 *
 * @component
 * @example
 * ```tsx
 * <BroadcastPlayer
 *   videoSource={{
 *     url: "https://broadcast.amazon.com/videos/123",
 *     title: "Tutorial Video",
 *     category: "Training"
 *   }}
 *   isPreview={true}
 * />
 * ```
 */
export const BroadcastPlayer: React.FC<BroadcastPlayerProps> = ({ videoSource, isPreview }) => {
  if (!videoSource.url) return null;

  /**
   * Common iframe properties used in both preview and full player modes
   */
  const commonIframeProps: DetailedHTMLProps<IframeHTMLAttributes<HTMLIFrameElement>, HTMLIFrameElement> = useMemo(
    () => ({
      className: isPreview ? 'broadcast-video-preview' : 'broadcast-video-player',
      src: videoSource.url || undefined,
      'aria-label': videoSource.title ?? 'Video Preview',
      title: videoSource.title ?? 'Video Preview',
      referrerPolicy: 'no-referrer-when-downgrade',
      allow: 'fullscreen',
      ...BROADCAST_VIDEO_CONSTANTS.IFRAME_SETTINGS,
      'data-testid': isPreview ? 'preview-player' : 'full-player'
    }),
    [videoSource.url, videoSource.title, isPreview]
  );

  /**
   * Renders the preview version of the player with a play button overlay
   */
  const renderPreviewPlayer = () => (
    <div className="broadcast-video-preview-container" data-testid="preview-container">
      <iframe {...commonIframeProps} height={BROADCAST_VIDEO_CONSTANTS.THUMBNAIL_DIMENSIONS.height} style={{ pointerEvents: 'none' }} />
      <button className="broadcast-video-play-button" data-testid="play-button" aria-label={`Play ${videoSource.title || 'video'}`} type="button">
        <Icon name="play" size="big" variant="normal" aria-hidden="true" />
      </button>
    </div>
  );

  /**
   * Renders the full video player
   */
  const renderFullPlayer = () => (
    <div className="broadcast-video-container" data-testid="video-container">
      <iframe
        {...commonIframeProps}
        title={videoSource.title ?? 'Amazon Broadcast Video'}
        aria-label={videoSource.title ?? 'Amazon Broadcast Video'}
        allowFullScreen
      />
    </div>
  );

  return isPreview ? renderPreviewPlayer() : renderFullPlayer();
};

/**
 * Default props for the BroadcastPlayer component
 */
BroadcastPlayer.defaultProps = {
  isPreview: false
};

export default BroadcastPlayer;
