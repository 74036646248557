/**
 * Regex pattern for validating Amazon Broadcast URLs
 * Format: https://broadcast.amazon.com/(videos|embed)/\d+
 * Examples:
 * - Valid: https://broadcast.amazon.com/videos/123456
 * - Valid: https://broadcast.amazon.com/embed/123456
 * - Invalid: https://broadcast.amazon.com/embedded/123456
 * - Invalid: https://broadcast.amazon.com/videos/abc
 */
export const BROADCAST_URL_PATTERN = /^https:\/\/broadcast\.amazon\.com\/(videos|embed)\/\d+$/ as RegExp;
export const BROADCAST_URL_EXAMPLE = 'https://broadcast.amazon.com/videos/123456' as const;

export const BROADCAST_VIDEO_CONSTANTS = {
  TRANSITION_DELAY: 100,
  MODAL_DIMENSIONS: {
    width: 1280
  },
  THUMBNAIL_DIMENSIONS: {
    height: 150
  },
  IFRAME_SETTINGS: {
    /**
     * Sandbox permissions:
     * - allow-scripts: Required to run video player scripts
     * - allow-presentation: Required for fullscreen functionality
     * - allow-same-origin: Required for Amazon Broadcast player to access its own origin
     */
    sandbox: 'allow-scripts allow-presentation allow-same-origin' as const,
    loading: 'lazy' as const,
    width: '100%',
    style: { border: 0, width: '100%', height: '100%' }
  },
  URL: {
    PATTERN: BROADCAST_URL_PATTERN,
    EXAMPLE: BROADCAST_URL_EXAMPLE,
    ERROR_MESSAGE: `Must be a valid Amazon Broadcast URL (e.g., ${BROADCAST_URL_EXAMPLE})`
  }
} as const;
