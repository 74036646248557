import { BROADCAST_VIDEO_CONSTANTS } from 'src/components/generic-components/broadcast-video';
import * as Yup from 'yup';

interface BroadcastUrlValidationOptions {
  isRequired?: boolean;
  requiredMessage?: string;
}

/**
 * Creates a Yup validation schema for broadcast URLs
 * @param options Configuration options for validation
 * @param options.isRequired Whether the field is required (default: false)
 * @param options.requiredMessage Custom message for required validation
 * @returns Yup validation schema for broadcast URLs
 */
export const createBroadcastUrlValidation = (options: BroadcastUrlValidationOptions = {}) => {
  const { isRequired = false, requiredMessage = 'This field is required' } = options;

  let schema = Yup.string().nullable();

  // Add required validation if specified
  if (isRequired) {
    schema = schema.required(requiredMessage);
  }

  // Add broadcast URL validation
  return schema.test('valid-broadcast-url', BROADCAST_VIDEO_CONSTANTS.URL.ERROR_MESSAGE, (value: string | null | undefined) => {
    // Allow empty/null values if field is not required
    if (!value) {
      return !isRequired;
    }
    // Validate URL pattern if value exists
    return BROADCAST_VIDEO_CONSTANTS.URL.PATTERN.test(value);
  });
};
