import React from 'react';
import { Box, Spinner } from '@amzn/awsui-components-react';

export const LoadingSpinner = () => {
  return (
    <Box data-testid="loading-spinner" margin="xxl" padding="xxl" textAlign="center">
      <Spinner size="large" />
    </Box>
  );
};
