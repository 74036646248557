import React, { useCallback, useMemo, useState } from 'react';
import { UserActionsEventTypes } from 'src/components/das-finsuite/das-finsuite-constants';
import { logger } from 'src/logger';
import { BroadcastPlayer } from './BroadcastPlayer';
import { VideoSource } from './types';
import { VideoModal } from './VideoModal';
import { LOG_EVENTS } from 'src/constants/LogEvents';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from '../ErrorFallback';
import { transformBroadcastUrl } from './utils/BroadcastUrlUtils';

interface VideoThumbnailProps {
  link: string;
  displayTitle: string;
  category?: string;
}

/**
 * VideoThumbnail component displays a clickable video preview that opens in a modal.
 * Includes accessibility features for keyboard navigation and screen readers.
 *
 * @param {string} link - The video URL
 * @param {string} displayTitle - The title of the video
 * @param {string} [category] - Optional category for the video
 *
 * @example
 * <VideoThumbnail
 *   link="https://broadcast.amazon.com/videos/123"
 *   displayTitle="Introduction Video"
 *   category="Training"
 * />
 */
export const VideoThumbnail: React.FC<VideoThumbnailProps> = ({ link, displayTitle, category }) => {
  const [showModal, setShowModal] = useState(false);

  if (!link) return null;

  const handleThumbnailClick = useCallback(() => {
    logger.info(LOG_EVENTS.VIDEO_CLICK, {
      event_type: UserActionsEventTypes.TUTORIALS_VIDEO_CLICKED,
      value: displayTitle
    });
    setShowModal(true);
  }, [displayTitle]);

  const handleModalClose = useCallback(() => {
    setShowModal(false);
  }, []);

  const videoSource: VideoSource = useMemo(
    () => ({
      url: transformBroadcastUrl.toEmbed(link),
      title: displayTitle,
      category
    }),
    [link, displayTitle, category]
  );

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        window.location.reload();
      }}
    >
      <div role="region" aria-label={`Video preview for ${displayTitle}`}>
        <button
          onClick={handleThumbnailClick}
          onKeyDown={(e) => e.key === 'Enter' && handleThumbnailClick()}
          aria-label={`Play ${displayTitle}`}
          aria-expanded={showModal}
          data-testid="video-thumbnail-button"
          className="video-thumbnail-button"
        >
          <BroadcastPlayer videoSource={videoSource} isPreview />
        </button>
        {/* Add aria-live region for important state changes */}
        <div aria-live="polite" className="sr-only">
          {showModal && 'Video modal opened'}
        </div>
      </div>
      <VideoModal isVisible={showModal} onClose={handleModalClose} videoSource={videoSource} />
    </ErrorBoundary>
  );
};
