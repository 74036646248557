import { BreadcrumbGroupProps } from '@amzn/awsui-components-react';
import { DFPHomeBreadcrumbs } from 'src/components/dfp-home-page/DFPHomePage';

export const DEFAULT_VIDEO_CATEGORY = 'General' as const;

export const TUTORIALS_ROUTE = '/tutorials' as const;

export const TUTORIALS_BREADCRUMBS: BreadcrumbGroupProps.Item[] = [
  ...DFPHomeBreadcrumbs,
  {
    text: 'Tutorials',
    href: TUTORIALS_ROUTE
  }
];
