import { eFinSuiteAdminMessages } from 'src/constants/AppConstants';
import * as Yup from 'yup';
import { infoPanelPageConfigurationSchema } from '../../homepage-manage/HomePageManagementSchema';
import { createBroadcastUrlValidation } from 'src/utilities/BroadcastUrlValidation';

export const APP_NAME_MAX_LENGTH = 30;
export const SHORT_DESCRIPTION_MAX_LENGTH = 60;
export const FULL_DESCRIPTION_MAX_LENGTH = 600;

export const WELCOME_TITLE_MAX_LENGTH = 21;
export const WELCOME_LINK_DISPLAY_NAME_MAX_LENGTH = 46;
export const WELCOME_LINK_DESCRIPTION_MAX_LENGTH = 1000;

export const MAX_TUTORIAL_LINKS = 50;
export const TUTORIAL_CATEGORY_MAX_LENGTH = 30;
export const TUTORIAL_DISPLAY_TITLE_MAX_LENGTH = 60;

export const OPTIONAL_MULTI_SELECT_DROPDOWN = Yup.array().of(
  Yup.object().shape({
    label: Yup.string(),
    id: Yup.string()
  })
);

export const REQUIRED_MULTI_SELECT_DROPDOWN = Yup.array()
  .of(
    Yup.object().shape({
      label: Yup.string().required(eFinSuiteAdminMessages.REQUIRED_FIELD),
      id: Yup.string().required(eFinSuiteAdminMessages.REQUIRED_FIELD)
    })
  )
  .min(1, eFinSuiteAdminMessages.AT_LEAST_ONE_SELECTION)
  .required(eFinSuiteAdminMessages.REQUIRED_FIELD);

export const REQUIRED_SELECT_DROPDOWN = Yup.object().shape({
  label: Yup.string().required(eFinSuiteAdminMessages.REQUIRED_FIELD),
  id: Yup.string().required(eFinSuiteAdminMessages.REQUIRED_FIELD)
});

export const nonProductionEnvironmentSchema = Yup.object().shape({
  id: Yup.mixed().nullable(),
  accessLevel: REQUIRED_SELECT_DROPDOWN,
  displayText: Yup.string().required(eFinSuiteAdminMessages.REQUIRED_FIELD),
  link: Yup.string().url(eFinSuiteAdminMessages.VALID_URL).required(eFinSuiteAdminMessages.REQUIRED_FIELD)
});

export const welcomeContentSchema = () =>
  Yup.object().shape({
    welcomeTitle: Yup.string()
      .required(eFinSuiteAdminMessages.REQUIRED_FIELD)
      .max(WELCOME_TITLE_MAX_LENGTH, `Max ${WELCOME_TITLE_MAX_LENGTH} characters`),
    welcomeContentDetails: Yup.array().min(1, eFinSuiteAdminMessages.AT_LEAST_ONE_RECORD)
  });

export const welcomeContentDetailsSchemaForApplications = Yup.object().shape({
  id: Yup.mixed().nullable(),
  displayText: Yup.string()
    .nullable()
    .required(eFinSuiteAdminMessages.REQUIRED_FIELD)
    .max(WELCOME_LINK_DISPLAY_NAME_MAX_LENGTH, `Max ${WELCOME_LINK_DISPLAY_NAME_MAX_LENGTH} characters`),
  link: Yup.string().nullable().url(eFinSuiteAdminMessages.VALID_URL),
  description: Yup.string().nullable().max(WELCOME_LINK_DESCRIPTION_MAX_LENGTH, `Max ${WELCOME_LINK_DESCRIPTION_MAX_LENGTH} characters`),
  pointOfContact: Yup.string()
    .nullable()
    .test(
      'pointOfContact-validation',
      'Use only alphabets, space, and one comma. Examples: "useralias" or "useralias, anotheralias"',
      function (value) {
        if (value === null || value === undefined || value === '') {
          return true; // Skip validation if null or empty string
        }
        // Apply regex match for non-empty strings
        return /^[a-zA-Z]+(\s*,\s*[a-zA-Z]+)?$/.test(value);
      }
    )
});

export const tutorialContentDetailsSchema = Yup.object().shape({
  id: Yup.mixed().nullable(),
  position: Yup.number()
    .typeError(eFinSuiteAdminMessages.ENTER_VALID_NUMBER)
    .nullable()
    .min(1, 'Value must be between 1 and 8')
    .max(8, 'Value must be between 1 and 8'),
  category: Yup.string().nullable().max(TUTORIAL_CATEGORY_MAX_LENGTH, `Max ${TUTORIAL_CATEGORY_MAX_LENGTH} characters`),
  displayTitle: Yup.string()
    .required(eFinSuiteAdminMessages.REQUIRED_FIELD)
    .max(TUTORIAL_DISPLAY_TITLE_MAX_LENGTH, `Max ${TUTORIAL_DISPLAY_TITLE_MAX_LENGTH} characters`),

  link: createBroadcastUrlValidation({
    isRequired: true,
    requiredMessage: eFinSuiteAdminMessages.REQUIRED_FIELD
  })
});

export const reportIssuesAndAdditionalDetailsSchema = Yup.object().shape({
  id: Yup.mixed().nullable(),
  position: Yup.number()
    .typeError(eFinSuiteAdminMessages.ENTER_VALID_NUMBER)
    .nullable()
    .min(1, 'Value must be between 1 and 8')
    .max(8, 'Value must be between 1 and 8'),
  displayName: Yup.string().required(eFinSuiteAdminMessages.REQUIRED_FIELD),
  link: Yup.string().url(eFinSuiteAdminMessages.VALID_URL).required(eFinSuiteAdminMessages.REQUIRED_FIELD)
});

export const shortDescriptionRegexPattern = new RegExp(`^(?=.{1,${SHORT_DESCRIPTION_MAX_LENGTH}}$).*$`);
export const SHORT_DESCRIPTION_ERROR_MESSAGE = `Max ${SHORT_DESCRIPTION_MAX_LENGTH} characters`;

export const applicationDetailsSchema = (existingApplicationNames: string[], shouldSkipAppNameUniquenessValidation: boolean) => {
  return Yup.object().shape({
    applicationName: Yup.string()
      .required(eFinSuiteAdminMessages.REQUIRED_FIELD)
      .max(APP_NAME_MAX_LENGTH, `Max ${APP_NAME_MAX_LENGTH} characters`)
      .test('unique-application-name', eFinSuiteAdminMessages.UNIQUE_APP_NAME_ERROR, async function (value) {
        if (shouldSkipAppNameUniquenessValidation) {
          return true; // Skip validation
        }
        if (existingApplicationNames && existingApplicationNames.includes(value || '')) {
          return false; // Validation failed
        }
        return true; // Validation passed
      }),
    applicationLink: Yup.string().url(eFinSuiteAdminMessages.VALID_URL),
    applicationOwnerLabelInput: REQUIRED_SELECT_DROPDOWN,
    applicationIntroductionVideoUrl: createBroadcastUrlValidation(),
    shortApplicationDescription: Yup.string()
      .required(eFinSuiteAdminMessages.REQUIRED_FIELD)
      .matches(shortDescriptionRegexPattern, `Max ${SHORT_DESCRIPTION_MAX_LENGTH} characters`),
    fullApplicationDescription: Yup.string()
      .required(eFinSuiteAdminMessages.REQUIRED_FIELD)
      .max(FULL_DESCRIPTION_MAX_LENGTH, `Max ${FULL_DESCRIPTION_MAX_LENGTH} characters`),
    glAccounts: REQUIRED_SELECT_DROPDOWN,
    toolUI: REQUIRED_SELECT_DROPDOWN
  });
};

export const linksAndTagsSchema = Yup.object().shape({
  hasNonProductionEnvironments: Yup.boolean().required(eFinSuiteAdminMessages.REQUIRED_FIELD),
  nonProductionEnvironments: Yup.array().when('hasNonProductionEnvironments', {
    is: true,
    then: Yup.array().min(1, eFinSuiteAdminMessages.AT_LEAST_ONE_RECORD).required(eFinSuiteAdminMessages.REQUIRED_FIELD),
    otherwise: Yup.array(nonProductionEnvironmentSchema)
  }),
  timeGranularity: OPTIONAL_MULTI_SELECT_DROPDOWN,
  keyDimension: OPTIONAL_MULTI_SELECT_DROPDOWN,
  lastEnhanced: OPTIONAL_MULTI_SELECT_DROPDOWN,
  process: OPTIONAL_MULTI_SELECT_DROPDOWN,
  scenario: OPTIONAL_MULTI_SELECT_DROPDOWN
});

export const homepageConfigurationSchema = Yup.object().shape({
  hasWelcomeContent: Yup.boolean().required(eFinSuiteAdminMessages.REQUIRED_FIELD),
  welcomeTitle: Yup.string().when('hasWelcomeContent', {
    is: true,
    then: Yup.string().required(eFinSuiteAdminMessages.REQUIRED_FIELD).max(WELCOME_TITLE_MAX_LENGTH, `Max ${WELCOME_TITLE_MAX_LENGTH} characters`),
    otherwise: Yup.string()
  }),
  welcomeContentDetails: Yup.array().when('hasWelcomeContent', {
    is: true,
    then: Yup.array().min(1, eFinSuiteAdminMessages.AT_LEAST_ONE_RECORD).required(eFinSuiteAdminMessages.REQUIRED_FIELD),
    otherwise: Yup.array(welcomeContentDetailsSchemaForApplications)
  }),
  hasAnnouncements: Yup.boolean().required(eFinSuiteAdminMessages.REQUIRED_FIELD),
  hasTutorials: Yup.boolean().required(eFinSuiteAdminMessages.REQUIRED_FIELD),
  tutorialContentDetails: Yup.array().when('hasTutorials', {
    is: true,
    then: Yup.array().min(1, eFinSuiteAdminMessages.AT_LEAST_ONE_RECORD).required(eFinSuiteAdminMessages.REQUIRED_FIELD),
    otherwise: Yup.array(tutorialContentDetailsSchema)
  }),
  hasFeedback: Yup.boolean().required(eFinSuiteAdminMessages.REQUIRED_FIELD)
});

export const ApplicationFormSchema = (applicationNames: string[], shouldSkipAppNameUniquenessValidation: boolean) =>
  Yup.object().shape({
    id: Yup.mixed().nullable(),
    applicationDetails: applicationDetailsSchema(applicationNames, shouldSkipAppNameUniquenessValidation),
    linksAndTags: linksAndTagsSchema,
    homePageConfigurations: homepageConfigurationSchema,
    infoPanelPageConfiguration: infoPanelPageConfigurationSchema
  });
