import { FlashbarProps } from '@amzn/awsui-components-react';
import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  fetchAllApplications,
  fetchAnnouncements,
  fetchApplicationDropdowns,
  fetchFavoriteApplications,
  fetchHomePageContent,
  fetchTeamPageContent
} from 'src/api/app-sync-services';
import { eLocalStorageKeys } from 'src/constants/AppConstants';
import { usePersistedState } from 'src/hooks/useLocalStorage';
import { logger } from 'src/logger';
import { AgGridLicenseService } from 'src/services';
import { HomepageEntity } from '../das-finsuite/admin-components/homepage-manage/HomepageModel';
import { FinTechTeams } from '../das-finsuite/admin-components/teams-manage/TeamManagementModel';
import { LoadingSpinner } from '../generic-components/LoadingSpinner';
import { AppContext, LoadingStatus } from './AppContextModels';
import { useAuth } from './AuthContextProvider';

const AppContextInitialData: AppContext = {
  isAdminControlsEnabled: null,
  setIsAdminControlsEnabled: () => {},
  contextLoadingError: null,
  contextLoadingStatus: LoadingStatus.NotInitiated,
  listOfApplications: [],
  setListOfApplications: () => {},
  parsedApplications: [],
  setParsedApplications: () => {},
  favoriteApplications: [],
  setFavoriteApplications: () => {},
  announcements: [],
  setAnnouncements: () => {},
  announcementsFlatEntity: [],
  setAnnouncementsFlatEntity: () => {},
  applicationMessages: [],
  displayApplicationFlashMessage: () => {},
  listApplicationDropdowns: undefined,
  setListApplicationDropdowns: () => {},
  homePageContent: {} as HomepageEntity,
  setHomePageContent: () => {},
  teamPageContent: {} as FinTechTeams,
  setTeamPageContent: () => {}
};

const AppContextDetails = createContext<AppContext>(AppContextInitialData);
export const useAppContext = () => useContext(AppContextDetails);

interface AppContextProviderProps {
  children: React.ReactNode;
}

export const AppContextProvider = ({ children }: AppContextProviderProps) => {
  const userAuthData = useAuth();
  const [appContext, setAppContext] = useState<AppContext>(AppContextInitialData);
  const [isAdminControlsEnabled, setIsAdminControlsEnabled] = usePersistedState(eLocalStorageKeys.IsAdminControlsEnabled, false);

  useEffect(() => {
    if (userAuthData.userAuthDataLoadingStatus === LoadingStatus.Completed) {
      loadAppContext();
    }
  }, [userAuthData]);

  const loadAppContext = async () => {
    logger.info('User authenticated successfully!');
    AgGridLicenseService.configureLicense();
    setAppContext((prev) => ({ ...prev, contextLoadingStatus: LoadingStatus.Loading }));

    try {
      const [homeContent, teamContent] = await Promise.all([fetchHomePageContent(userAuthData.Alias), fetchTeamPageContent(userAuthData.Alias)]);

      const [applications, dropdowns, favorites] = await Promise.all([
        fetchAllApplications(teamContent),
        fetchApplicationDropdowns(teamContent),
        fetchFavoriteApplications(userAuthData.Alias)
      ]);

      const announcements = await fetchAnnouncements(applications.allApplications);

      setAppContext((prev) => ({
        ...prev,
        listOfApplications: applications.allApplications,
        parsedApplications: applications.applicationFlatTableEntity,
        listApplicationDropdowns: dropdowns,
        favoriteApplications: favorites,
        announcements: announcements.announcementList,
        announcementsFlatEntity: announcements.announcementsFlatTableEntity,
        homePageContent: homeContent,
        teamPageContent: teamContent,
        contextLoadingStatus: LoadingStatus.Completed
      }));
    } catch (error) {
      handleError('Error while loading context. Some of the features in Application might not work as expected.');
    }
  };

  const handleError = (errorMessage: string) => {
    setAppContext((prev) => ({
      ...prev,
      listOfApplications: [],
      parsedApplications: [],
      contextLoadingError: errorMessage,
      contextLoadingStatus: LoadingStatus.Failed
    }));
    logger.error(errorMessage);
  };

  const displayApplicationFlashMessage = (content: string, flashBarType: FlashbarProps.Type) => {
    const newFlashMessage: FlashbarProps.MessageDefinition = {
      type: flashBarType,
      content: content,
      dismissible: true,
      dismissLabel: 'Dismiss message',
      onDismiss: () => setAppContext((prev) => ({ ...prev, applicationMessages: [] }))
    };
    addFlashbarItem(newFlashMessage);
  };

  const addFlashbarItem = (item: FlashbarProps.MessageDefinition) => {
    setAppContext((prev) => {
      const newMessages = prev.applicationMessages.length >= 1 ? [...prev.applicationMessages.slice(1), item] : [...prev.applicationMessages, item];
      return { ...prev, applicationMessages: newMessages };
    });
  };

  if (userAuthData.userAuthDataLoadingStatus === LoadingStatus.Loading || appContext.contextLoadingStatus === LoadingStatus.Loading) {
    return <LoadingSpinner />;
  }

  const contextValue: AppContext = {
    ...appContext,
    isAdminControlsEnabled,
    setIsAdminControlsEnabled,
    displayApplicationFlashMessage,
    setListOfApplications: (applications) => setAppContext((prev) => ({ ...prev, listOfApplications: applications })),
    setParsedApplications: (parsedApplications) => setAppContext((prev) => ({ ...prev, parsedApplications })),
    setFavoriteApplications: (favoriteApplications) => setAppContext((prev) => ({ ...prev, favoriteApplications })),
    setAnnouncements: (announcements) => setAppContext((prev) => ({ ...prev, announcements })),
    setAnnouncementsFlatEntity: (announcements) => setAppContext((prev) => ({ ...prev, announcementsFlatEntity: announcements })),
    setListApplicationDropdowns: (applicationDropdowns) => setAppContext((prev) => ({ ...prev, listApplicationDropdowns: applicationDropdowns })),
    setHomePageContent: (homePageContent) => setAppContext((prev) => ({ ...prev, homePageContent })),
    setTeamPageContent: (teamPageContent) => setAppContext((prev) => ({ ...prev, teamPageContent }))
  };

  return <AppContextDetails.Provider value={contextValue}>{children}</AppContextDetails.Provider>;
};
