import Board from '@amzn/awsui-board-components/board';
import { SpaceBetween } from '@amzn/awsui-components-react';
import React, { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { StoredWidgetPlacement } from 'src/components/context/AppContextModels';
import { EmptyState } from 'src/components/generic-components/empty-state';
import { ErrorFallback } from 'src/components/generic-components/ErrorFallback';
import { useBoardItemsLayout } from 'src/components/hooks/useBoardItemsLayout';
import { eLocalStorageKeys } from 'src/constants/AppConstants';
import { boardI18nStrings } from 'src/i18n-strings/board-I18nStrings';
import { TeamEntity } from '../../admin-components/teams-manage/TeamManagementModel';
import { ConfigurableWidget } from '../../configurable-dashboard/components/configurable-widget';
import { exportLayout } from '../../configurable-dashboard/Utils/ConfigurableDashboardUtils';
import { FinTechTeamIntroWidget } from '../../widgets/fintech-team-intro-widget/FinTechTeamIntroWidget';
import { WidgetConfig } from '../../widgets/interface';
import { WelcomeWidget } from '../../widgets/welcome-widget/WelcomeWidget';
import { CONSTANTS } from '../constants';

interface TeamTabContentProps {
  fintechTeam: TeamEntity;
  onError: (error: Error) => void;
  resetRef: React.MutableRefObject<(() => void) | undefined>;
}

// Default layout configuration for the FinTech page
const FIN_TECH_DEFAULT_LAYOUT: ReadonlyArray<StoredWidgetPlacement> = [{ id: 'welcomeWidget' }, { id: 'teamIntroVideo' }];
const FIN_TECH_RESOURCE_WIDGET_DEFAULT_CONFIG = { defaultRowSpan: 6, defaultColumnSpan: 2 };

/**
 * TeamTabContent component displays a configurable dashboard for team-specific content
 */
export const TeamTabContent: React.FC<TeamTabContentProps> = ({ fintechTeam, onError, resetRef }) => {
  // Initialize board layout management with localStorage persistence
  const [ref, layout, setLayout, resetLayout] = useBoardItemsLayout(
    `${eLocalStorageKeys.FINTECH_TEAM_PAGE_WIDGETS_LAYOUT}-${fintechTeam.id}`,
    FIN_TECH_DEFAULT_LAYOUT
  );

  useEffect(() => {
    resetRef.current = resetLayout;
  }, []);

  const welcomeWidget = WelcomeWidget({
    welcomeTitle: CONSTANTS.BOARD.SECTIONS.RESOURCES,
    description: '',
    welcomeContentDetails: fintechTeam.teamWelcomeDetails
  });

  const teamIntroVideo = FinTechTeamIntroWidget({
    introTitle: CONSTANTS.BOARD.SECTIONS.TEAM_INTRO,
    description: '',
    team: fintechTeam
  });

  // Map of all available widgets
  const allWidgets: Record<string, WidgetConfig> = {
    welcomeWidget,
    teamIntroVideo
  };

  /**
   * Transforms layout configuration into board widget items
   * @param layout - Array of widget placement configurations
   */
  const getBoardWidgets = (layout: ReadonlyArray<StoredWidgetPlacement>) => {
    return layout.map((position) => {
      const widget = allWidgets[position.id];

      const defaultDefinition = position.id === 'welcomeWidget' ? FIN_TECH_RESOURCE_WIDGET_DEFAULT_CONFIG : widget.definition;

      return {
        ...position,
        ...widget,
        definition: defaultDefinition,
        columnSpan: position.columnSpan ?? defaultDefinition?.defaultColumnSpan ?? 1,
        rowSpan: position.rowSpan ?? defaultDefinition?.defaultRowSpan ?? 2
      };
    });
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={onError} data-testid="team-tab-error-boundary">
      <div ref={ref} data-testid="team-tab-container">
        <Board
          i18nStrings={boardI18nStrings}
          empty={
            <EmptyState
              title={CONSTANTS.EMPTY_STATES.NO_WIDGETS.TITLE}
              description={CONSTANTS.EMPTY_STATES.NO_WIDGETS.DESCRIPTION}
              verticalCenter={true}
              action={<SpaceBetween direction="horizontal" size="xs" />}
              data-testid="team-tab-empty-state"
            />
          }
          items={getBoardWidgets(layout)}
          onItemsChange={({ detail: { items } }) => {
            setLayout(exportLayout(items));
          }}
          renderItem={(item, actions) => {
            const Wrapper = item.data.provider ?? React.Fragment;
            return (
              <Wrapper>
                <ConfigurableWidget config={item.data} onRemove={actions.removeItem} data-testid={`widget-${item.id}`} />
              </Wrapper>
            );
          }}
          data-testid="team-tab-board"
        />
      </div>
    </ErrorBoundary>
  );
};
