import { Box, Header } from '@amzn/awsui-components-react';
import React from 'react';
import BroadcastPlayer from 'src/components/generic-components/broadcast-video/BroadcastPlayer';
import { TeamEntity } from '../../admin-components/teams-manage/TeamManagementModel';
import { WidgetConfig } from '../interface';

const WIDGET_CONSTANTS = {
  DEFINITION: {
    defaultRowSpan: 6,
    defaultColumnSpan: 2,
    minRowSpan: 6,
    minColumnSpan: 2
  },
  CATEGORY: 'Team Introduction',
  DEFAULT_DESCRIPTION: ''
} as const;

interface FinTechTeamIntroWidgetProps {
  introTitle: string;
  description?: string;
  team: TeamEntity;
}

interface FinTechTeamIntroWidgetHeaderProps {
  title: string;
  description?: string;
}

interface FinTechTeamIntroWidgetContentProps {
  team: TeamEntity;
}

// Separate components into their own files if they grow larger
const FinTechTeamIntroWidgetHeader: React.FC<FinTechTeamIntroWidgetHeaderProps> = ({ title, description }) => (
  <Header description={description} data-testid="team-intro-header">
    <Box variant="h2">{title}</Box>
  </Header>
);

const FinTechTeamIntroWidgetContent: React.FC<FinTechTeamIntroWidgetContentProps> = ({ team }) => {
  const videoSource = {
    url: team.teamIntroductionVideoUrl,
    title: `${team.teamName} Introduction Video`,
    category: WIDGET_CONSTANTS.CATEGORY
  };

  return (
    <div data-testid="team-intro-content">
      <div className="broadcast-video-container">
        <BroadcastPlayer videoSource={videoSource} isPreview={false} data-testid="broadcast-player" />
      </div>
      <Box variant="p">
        <p>{team.teamOverView}</p>
      </Box>
    </div>
  );
};

// Main widget configuration function
export const FinTechTeamIntroWidget = ({
  introTitle,
  description = WIDGET_CONSTANTS.DEFAULT_DESCRIPTION,
  team
}: FinTechTeamIntroWidgetProps): WidgetConfig => ({
  definition: WIDGET_CONSTANTS.DEFINITION,
  data: {
    icon: 'list',
    title: introTitle,
    description,
    header: <FinTechTeamIntroWidgetHeader title={introTitle} description={description} />,
    content: <FinTechTeamIntroWidgetContent team={team} />
  }
});

export { FinTechTeamIntroWidgetHeader, FinTechTeamIntroWidgetContent };
