import {
  Box,
  Checkbox,
  Container,
  Flashbar,
  FormField,
  Header,
  Input,
  SpaceBetween,
  StatusIndicator,
  Tabs,
  Textarea
} from '@amzn/awsui-components-react';
import { FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { ShowModalInfo } from 'src/components/generic-components/ConfirmationModal';
import { characterCountConstraintMessage } from 'src/utilities/CommonUtilities';
import { TutorialContentDetailsEntity, WelcomeContentDetailsEntity } from '../../AdminModels';
import { removeQuotesFromString } from '../../applications/admin-manage-application/StepsUtilities';
import { MAX_TUTORIAL_LINKS, WELCOME_TITLE_MAX_LENGTH } from '../../applications/admin-manage-application/StepsValidations';
import TutorialContentForm, { TutorialContentFormMethods } from '../../applications/admin-manage-application/TutorialContentForm';
import { TutorialsContentTable } from '../../applications/admin-manage-application/TutorialsContentTable';
import WelcomeLinksContentForm, { WelcomeLinksContentFormMethods } from '../../applications/admin-manage-application/WelcomeLinksContentForm';
import { WelcomeContentTable } from '../../applications/admin-manage-application/WelcomeWidgetTable';
import { HomePageWizard } from '../HomepageModel';
import { MAX_CHARACTER_COUNT } from '../HomePageManagementSchema';
import { BROADCAST_URL_EXAMPLE } from 'src/components/generic-components/broadcast-video';

interface HomePageContentStep1Props {
  formik: FormikProps<HomePageWizard>;
}

const HomePageContentStep1: React.FC<HomePageContentStep1Props> = ({ formik }) => {
  const [showModalInfo, setShowModalInfo] = useState<ShowModalInfo>({
    showModal: false,
    eventHeader: '',
    eventContentType: ''
  });

  const welcomeContentFormRef = useRef<WelcomeLinksContentFormMethods>(null);
  const [welcomeContentDetails, setWelcomeContentDetails] = useState<WelcomeContentDetailsEntity[]>(
    formik.values?.homePageDetailsAndWidgets?.welcomeContentDetails ?? []
  );
  const [selectedWelcomeContentDetails, setSelectedWelcomeContentDetails] = useState<WelcomeContentDetailsEntity[]>([]);

  const handleWelcomeContentUpdate = (updatedContent: WelcomeContentDetailsEntity[]) => {
    setWelcomeContentDetails(updatedContent);
    formik.setFieldValue('homePageDetailsAndWidgets.welcomeContentDetails', updatedContent);
  };

  const addNewWelcomeContent = (newContent: WelcomeContentDetailsEntity) => {
    const updatedContent = [...welcomeContentDetails, newContent];
    handleWelcomeContentUpdate(updatedContent);
  };

  const removeSelectedWelcomeContent = () => {
    const updatedContent = welcomeContentDetails.filter((obj) => !selectedWelcomeContentDetails.includes(obj));
    handleWelcomeContentUpdate(updatedContent);
    setSelectedWelcomeContentDetails([]);
  };

  const updatedWelcomeContent = (welcomeContent: WelcomeContentDetailsEntity) => {
    const updatedContent = welcomeContentDetails.map((obj) => (obj.id === welcomeContent.id ? welcomeContent : obj));
    handleWelcomeContentUpdate(updatedContent);
  };

  const editWelcomeContent = () => {
    if (welcomeContentFormRef.current && selectedWelcomeContentDetails[0]) {
      welcomeContentFormRef.current.editSelectedRow(selectedWelcomeContentDetails[0]);
      setSelectedWelcomeContentDetails([]);
    }
  };

  const tableDescriptionForErrors = formik.touched.homePageDetailsAndWidgets?.welcomeContentDetails &&
    formik.errors.homePageDetailsAndWidgets?.welcomeContentDetails && (
      <StatusIndicator type="error">
        {removeQuotesFromString(JSON.stringify(formik.errors.homePageDetailsAndWidgets?.welcomeContentDetails))}
      </StatusIndicator>
    );

  const { homePageDetailsAndWidgets } = formik.values;
  const hasWelcomeContent = homePageDetailsAndWidgets?.hasWelcomeContent ?? false;

  useEffect(() => {
    setWelcomeContentDetails(formik.values.homePageDetailsAndWidgets.welcomeContentDetails || []);
  }, [formik]);

  // Announcements
  const [announcementItems, setAnnouncementItems] = React.useState<any>([
    {
      type: 'info',
      dismissible: false,
      dismissLabel: 'Dismiss message',
      content: <>{`Announcements widget has been enabled`}</>,
      id: 'message_1'
    }
  ]);

  // Tutorials
  const tutorialContentFormRef = useRef<TutorialContentFormMethods>(null);
  const [tutorialContentActiveTabId, setTutorialContentActiveTabId] = useState('first');

  const [tutorialContentDetails, setTutorialContentDetails] = useState<TutorialContentDetailsEntity[]>(
    formik.values?.homePageDetailsAndWidgets?.tutorialContentDetails ? formik.values?.homePageDetailsAndWidgets?.tutorialContentDetails : []
  );
  const [selectedTutorialContentDetails, setSelectedTutorialContentDetails] = useState<any[]>([]);

  const addNewTutorialContent = (addNewTutorialContent: TutorialContentDetailsEntity) => {
    if (tutorialContentDetails.length < MAX_TUTORIAL_LINKS) {
      const newRow: TutorialContentDetailsEntity[] = [addNewTutorialContent];
      const finalRows = tutorialContentDetails.concat(newRow);
      setTutorialContentDetails(finalRows);
      formik.setFieldValue('homePageDetailsAndWidgets.tutorialContentDetails', finalRows);
    }
  };

  const removeSelectedTutorialContent = () => {
    const finalRow = tutorialContentDetails.filter((obj) => !selectedTutorialContentDetails.includes(obj));
    setTutorialContentDetails(finalRow);
    formik.setFieldValue('homePageDetailsAndWidgets.tutorialContentDetails', finalRow);
    setSelectedTutorialContentDetails([]);
  };

  const clearNewTutorialDetails = () => {
    setSelectedTutorialContentDetails([]);
  };

  const editTutorialContent = () => {
    if (tutorialContentFormRef.current) {
      tutorialContentFormRef.current.editTutorialContentChanged(selectedTutorialContentDetails[0]);
      setSelectedTutorialContentDetails([]);
    }
  };

  const updateTutorialContent = (updatedTutorialContent: TutorialContentDetailsEntity) => {
    const finalRow = tutorialContentDetails.map((obj) => (obj.id === updatedTutorialContent.id ? updatedTutorialContent : obj));
    setTutorialContentDetails(finalRow);
    formik.setFieldValue('homePageDetailsAndWidgets.tutorialContentDetails', finalRow);
  };

  // Feedback
  const [feedbackItems, setFeedbackItems] = React.useState<any>([
    {
      type: 'info',
      dismissible: false,
      dismissLabel: 'Dismiss message',
      content: <>{`Feedback widget has been enabled`}</>,
      id: 'message_1'
    }
  ]);

  return (
    <SpaceBetween size="m" direction="vertical">
      <Container variant="stacked" header={<Header variant="h2">Overview</Header>}>
        <SpaceBetween size="l" direction="vertical">
          <FormField
            label="Detailed description"
            description={`These details will be displayed on the application’s info panel.`}
            constraintText={characterCountConstraintMessage(
              MAX_CHARACTER_COUNT.DETAILED_DESCRIPTION,
              homePageDetailsAndWidgets?.fullApplicationDescription?.length ?? 0
            )}
            errorText={
              formik.touched.homePageDetailsAndWidgets?.fullApplicationDescription &&
              formik.errors.homePageDetailsAndWidgets?.fullApplicationDescription
            }
          >
            <Textarea
              placeholder="Describe the application in detail"
              value={homePageDetailsAndWidgets.fullApplicationDescription}
              onChange={({ detail }) => formik.setFieldValue('homePageDetailsAndWidgets.fullApplicationDescription', detail.value)}
              onBlur={() => formik.setFieldTouched('homePageDetailsAndWidgets.fullApplicationDescription', true)}
            />
          </FormField>

          <FormField
            label={
              <>
                Intro video URL<i> - optional</i>
              </>
            }
            description="Link a broadcast video where users can get an intro to your app"
            errorText={
              formik.touched.homePageDetailsAndWidgets?.applicationIntroductionVideoUrl &&
              formik.errors.homePageDetailsAndWidgets?.applicationIntroductionVideoUrl
            }
          >
            <Input
              placeholder={BROADCAST_URL_EXAMPLE}
              value={homePageDetailsAndWidgets.applicationIntroductionVideoUrl}
              onChange={({ detail }) => formik.setFieldValue('homePageDetailsAndWidgets.applicationIntroductionVideoUrl', detail.value)}
              onBlur={() => formik.setFieldTouched('homePageDetailsAndWidgets.applicationIntroductionVideoUrl', true)}
            />
          </FormField>
        </SpaceBetween>
      </Container>

      <Container
        variant="stacked"
        header={
          <Header
            variant="h2"
            description="Provide users with key quick links (e.g., documentation, wikis) to help introduce your app"
            actions={
              <SpaceBetween size="m" direction="horizontal">
                <Checkbox
                  disabled
                  checked={hasWelcomeContent}
                  onChange={({ detail }) => formik.setFieldValue('homePageDetailsAndWidgets.hasWelcomeContent', detail.checked)}
                  onBlur={() => formik.setFieldTouched('homePageDetailsAndWidgets.hasWelcomeContent', true)}
                >{`Enable`}</Checkbox>
              </SpaceBetween>
            }
          >
            Welcome
          </Header>
        }
      >
        {hasWelcomeContent && (
          <SpaceBetween size="m" direction="vertical">
            <Box padding={{ bottom: 'm' }}>
              <FormField
                constraintText={characterCountConstraintMessage(WELCOME_TITLE_MAX_LENGTH, homePageDetailsAndWidgets?.welcomeTitle?.length ?? 0)}
                errorText={formik.touched.homePageDetailsAndWidgets?.welcomeTitle && formik.errors.homePageDetailsAndWidgets?.welcomeTitle}
                stretch={true}
                label="Widget Title"
              >
                <Input
                  placeholder="Enter custom title"
                  onChange={({ detail }) => formik.setFieldValue('homePageDetailsAndWidgets.welcomeTitle', detail.value)}
                  onBlur={() => formik.setFieldTouched('homePageDetailsAndWidgets.welcomeTitle', true)}
                  value={homePageDetailsAndWidgets?.welcomeTitle ?? ''}
                />
              </FormField>
            </Box>

            <WelcomeLinksContentForm
              ref={welcomeContentFormRef}
              showWelcomeContentModal={false}
              hasWelcomeContent={hasWelcomeContent}
              addNewWelcomeContent={addNewWelcomeContent}
              updateRow={updatedWelcomeContent}
            />

            <WelcomeContentTable
              tableDescriptionForErrors={tableDescriptionForErrors}
              editWelcomeContent={editWelcomeContent}
              selectedWelcomeContentDetails={selectedWelcomeContentDetails}
              deleteSelected={removeSelectedWelcomeContent}
              welcomeContentDetails={welcomeContentDetails}
              setSelectedWelcomeContentDetails={setSelectedWelcomeContentDetails}
            />
          </SpaceBetween>
        )}
      </Container>

      {/* Announcements  */}
      <Container
        header={
          <Header
            variant="h2"
            description="Display to users all announcements that apply to this app"
            actions={
              <>
                <Checkbox
                  disabled
                  onChange={({ detail }) => {
                    formik.setFieldValue('homePageDetailsAndWidgets.hasAnnouncements', detail.checked);
                  }}
                  onBlur={() => {
                    formik.setFieldTouched('homePageDetailsAndWidgets.hasAnnouncements', true);
                  }}
                  checked={formik.values?.homePageDetailsAndWidgets?.hasAnnouncements || false}
                >
                  Enable
                </Checkbox>
              </>
            }
          >
            Announcements
          </Header>
        }
      >
        {formik.values?.homePageDetailsAndWidgets?.hasAnnouncements && <Flashbar items={announcementItems} />}
      </Container>

      <Container
        header={
          <Header
            variant="h2"
            description={`Show tutorials or training videos for this app (8 links are showcased on the widget itself and the app’s tutorial page will display all links)`}
            actions={
              <>
                <Checkbox
                  disabled
                  onChange={({ detail }) => {
                    formik.setFieldValue('homePageDetailsAndWidgets.hasTutorials', detail.checked);
                    clearNewTutorialDetails();
                  }}
                  onBlur={() => {
                    formik.setFieldTouched('homePageDetailsAndWidgets.hasTutorials', true);
                  }}
                  checked={formik.values?.homePageDetailsAndWidgets?.hasTutorials || false}
                >
                  Enable
                </Checkbox>
              </>
            }
          >
            Tutorials
          </Header>
        }
      >
        {formik.values?.homePageDetailsAndWidgets?.hasTutorials && (
          <>
            {/* Tutorials content  */}
            <Tabs
              onChange={({ detail }) => setTutorialContentActiveTabId(detail.activeTabId)}
              activeTabId={tutorialContentActiveTabId}
              tabs={[
                {
                  label: 'Content',
                  id: 'first',
                  content: (
                    <>
                      <SpaceBetween size="m" direction="vertical">
                        <TutorialContentForm
                          ref={tutorialContentFormRef}
                          hasTutorialContent={formik.values.homePageDetailsAndWidgets.hasTutorials || false}
                          addNewTutorialContent={addNewTutorialContent}
                          updateTutorialContent={updateTutorialContent}
                          tutorialContentDetails={formik.values.homePageDetailsAndWidgets.tutorialContentDetails || []}
                        />

                        <TutorialsContentTable
                          formik={formik}
                          selectedTutorialContentDetails={selectedTutorialContentDetails}
                          editTutorialContent={editTutorialContent}
                          setShowModalInfo={setShowModalInfo}
                          tutorialContentDetails={tutorialContentDetails}
                          setSelectedTutorialContentDetails={setSelectedTutorialContentDetails}
                        />
                      </SpaceBetween>
                    </>
                  )
                },
                {
                  label: 'Preview',
                  id: 'second',
                  content: (
                    <>
                      <div>Second tab content area</div>
                    </>
                  )
                }
              ]}
            />
          </>
        )}
      </Container>

      <Container
        header={
          <Header
            variant="h2"
            className="limited-width-header"
            description="Collects experience ratings and feedback for this app"
            actions={
              <>
                <Checkbox
                  disabled
                  onChange={({ detail }) => {
                    formik.setFieldValue('homePageDetailsAndWidgets.hasFeedback', detail.checked);
                  }}
                  onBlur={() => {
                    formik.setFieldTouched('homePageDetailsAndWidgets.hasFeedback', true);
                  }}
                  checked={formik.values?.homePageDetailsAndWidgets?.hasFeedback || false}
                >
                  Enable
                </Checkbox>
              </>
            }
          >
            Feedback
          </Header>
        }
      >
        {formik.values?.homePageDetailsAndWidgets?.hasFeedback && <Flashbar items={feedbackItems} />}
      </Container>
    </SpaceBetween>
  );
};

export default HomePageContentStep1;
