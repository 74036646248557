import { BreadcrumbGroupProps } from '@amzn/awsui-components-react';
import { DEFAULT_SIDE_PANEL_DETAILS } from '../homepage-manage/HomePageManagementUtils';
import { FinTechTeams, TeamPageWizard } from './TeamManagementModel';
import { v4 as uuidv4 } from 'uuid';
import HelpPanelConfiguration from '../applications/admin-manage-application/Step4';
import { TeamPageStep1 } from './steps/TeamPageStep1';
import { createItemMetadata, updateItemMetadata } from 'src/utilities/ItemMetadata';
import { TeamPageStep2 } from './steps/TeamPageStep2';
import { AdminBaseBreadcrumbs } from '../AdminHomePageConfig';

export const getTeamPageManagementBreadcrumbItems = (): BreadcrumbGroupProps.Item[] => [
  ...AdminBaseBreadcrumbs,
  {
    text: 'DaS FinTech Team',
    href: '/admin/manage-team-page'
  }
];

// Default state for team page content
export const getTeamContentDefaultState = (alias: string): FinTechTeams => ({
  id: uuidv4(),
  enableTeamInfoTabs: true,
  teams: [],
  sidePanelDetails: DEFAULT_SIDE_PANEL_DETAILS,
  itemMetadata: createItemMetadata(alias)
});

// Default state for team page wizard
export const getTeamWizardContentDefaultState = (alias: string): TeamPageWizard => ({
  id: uuidv4(),
  teamManagement: [],
  teamPageDetailsAndWidgets: {
    enableTeamInfoTabs: true,
    teams: []
  },
  infoPanelPageConfiguration: {
    sidePanelDetails: DEFAULT_SIDE_PANEL_DETAILS
  },
  itemMetadata: createItemMetadata(alias)
});

// Convert team page entity to wizard format
export const teamPageContentAPIToWizard = (teamPageEntity: FinTechTeams, userAlias: string): TeamPageWizard => ({
  id: teamPageEntity.id,
  teamManagement: teamPageEntity.teams.map((team) => ({
    id: team.id,
    isNewTeam: false,
    teamName: team.teamName
  })),
  teamPageDetailsAndWidgets: {
    enableTeamInfoTabs: teamPageEntity.enableTeamInfoTabs,
    teams: teamPageEntity.teams.map((team) => ({
      id: team.id,
      teamName: team.teamName,
      teamIntroductionVideoUrl: team.teamIntroductionVideoUrl,
      teamOverView: team.teamOverView,
      teamWelcomeDetails: team.teamWelcomeDetails.map((welcomeDetail) => ({
        id: welcomeDetail.id,
        displayText: welcomeDetail.displayText,
        link: welcomeDetail.link,
        description: welcomeDetail.description,
        pointOfContact: welcomeDetail.pointOfContact === '' ? null : welcomeDetail.pointOfContact
      }))
    }))
  },
  infoPanelPageConfiguration: {
    sidePanelDetails: teamPageEntity.sidePanelDetails
  },
  itemMetadata: updateItemMetadata(userAlias, teamPageEntity.itemMetadata)
});

// Convert team page wizard to entity format
export const teamPageContentWizardToAPI = (teamPageWizard: TeamPageWizard): FinTechTeams => ({
  id: teamPageWizard.id,
  enableTeamInfoTabs: teamPageWizard.teamPageDetailsAndWidgets.enableTeamInfoTabs,
  teams: teamPageWizard.teamPageDetailsAndWidgets.teams,
  sidePanelDetails: teamPageWizard.infoPanelPageConfiguration.sidePanelDetails,
  itemMetadata: teamPageWizard.itemMetadata
});

export const getTeamManagementBreadcrumbItems = (): BreadcrumbGroupProps.Item[] => [
  ...AdminBaseBreadcrumbs,
  {
    text: 'DaS FinTech Team',
    href: '/admin/manage-team-page'
  }
];

export const TeamPageWizardSteps: any[] = [
  {
    title: 'Manage teams',
    description: '',
    stateKey: 'manageTeams',
    StepContent: TeamPageStep1,
    isOptional: false
  },
  {
    title: 'Team page widgets',
    description: '',
    stateKey: 'teamPageDetails',
    StepContent: TeamPageStep2,
    isOptional: false
  },
  {
    title: 'Side panel details',
    description: '',
    stateKey: 'helpPanelConfiguration',
    StepContent: HelpPanelConfiguration,
    isOptional: false
  }
];
