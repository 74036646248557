import Box from '@amzn/awsui-components-react/polaris/box';
import Button from '@amzn/awsui-components-react/polaris/button';
import Container from '@amzn/awsui-components-react/polaris/container';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import React, { useCallback, useMemo, useState } from 'react';
import { LoadingSpinner } from 'src/components/generic-components/LoadingSpinner';
import { BroadcastPlayer } from './BroadcastPlayer';
import { BroadcastVideoLink } from './BroadcastVideoLink';
import { BROADCAST_VIDEO_CONSTANTS } from './constants';
import { ModalProps, VideoSource } from './types';

interface VideoModalProps extends ModalProps {
  videoSource: VideoSource;
}

/**
 * VideoModal component displays a video player in a modal dialog.
 *
 * @param {boolean} isVisible - Controls the visibility of the modal
 * @param {() => void} onClose - Callback function when modal is closed
 * @param {VideoSource} videoSource - Video source information including URL and metadata
 *
 * @example
 * <VideoModal
 *   isVisible={showModal}
 *   onClose={handleClose}
 *   videoSource={{
 *     url: "https://broadcast.amazon.com/videos/123",
 *     title: "Tutorial Video",
 *     category: "Training"
 *   }}
 * />
 */
export const VideoModal: React.FC<VideoModalProps> = ({ isVisible, onClose, videoSource }) => {
  const [isTransitioning, setIsTransitioning] = useState(false);

  const handleClose = useCallback(() => {
    setIsTransitioning(true);
    const timer = setTimeout(() => {
      setIsTransitioning(false);
      onClose();
    }, BROADCAST_VIDEO_CONSTANTS.TRANSITION_DELAY);

    return () => clearTimeout(timer);
  }, [onClose]);

  const modalFooter = useMemo(
    () => (
      <Box float="right" textAlign="center">
        <SpaceBetween direction="horizontal" size="m" alignItems="center">
          <BroadcastVideoLink title={videoSource.title} link={videoSource.url} />
          <Button variant="primary" onClick={handleClose} data-testid="modal-close-button" ariaLabel="Close video">
            Close
          </Button>
        </SpaceBetween>
      </Box>
    ),
    [handleClose]
  );

  const modalContent = useMemo(
    () => (
      <div className="broadcast-video-modal-content" data-testid="modal-content">
        <Container
          className="broadcast-video-modal-content-container"
          disableContentPaddings
          disableHeaderPaddings
          fitHeight
          media={{
            width: BROADCAST_VIDEO_CONSTANTS.MODAL_DIMENSIONS.width,
            content: (
              <div className="broadcast-video-container" data-testid="modal-video-wrapper">
                {isTransitioning ? (
                  <div className="broadcast-video-loading" data-testid="modal-loading">
                    <LoadingSpinner />
                  </div>
                ) : (
                  <BroadcastPlayer videoSource={videoSource} />
                )}
              </div>
            )
          }}
        />
      </div>
    ),
    [isTransitioning, videoSource]
  );

  return (
    <Modal
      onDismiss={handleClose}
      visible={isVisible}
      size="large"
      data-testid="video-modal"
      footer={modalFooter}
      header={videoSource.title ?? ''}
      closeAriaLabel="Close video modal"
    >
      {modalContent}
    </Modal>
  );
};
