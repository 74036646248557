import { Box, ExpandableSection, SpaceBetween, TextContent } from '@amzn/awsui-components-react';
import HelpPanel from '@amzn/awsui-components-react/polaris/help-panel';
import React, { useMemo } from 'react';
import { RichTextContentWidget, SidePanelDetails } from 'src/components/context/AppContextModels';
import { VideoThumbnail } from 'src/components/generic-components/broadcast-video/VideoThumbnail';
import { eFinSuiteMessages } from 'src/constants/AppConstants';
import { sanitizeHtml } from 'src/utilities/SanitizeHtml';

export interface InfoPanelDetailsHelpPanelProps {
  headerName: string;
  sidePanelDetails: SidePanelDetails | undefined;
  appDetailedDescription?: string;
  applicationIntroductionVideoUrl?: string | null;
  displayingInForm?: boolean;
}

export const InfoPanelDetailsHelpPanel: React.FC<InfoPanelDetailsHelpPanelProps> = (props: InfoPanelDetailsHelpPanelProps) => {
  const { displayingInForm, headerName, sidePanelDetails, appDetailedDescription, applicationIntroductionVideoUrl } = props;

  const isNoInformationResourcesAvailable = useMemo(() => {
    return !(
      appDetailedDescription ||
      applicationIntroductionVideoUrl ||
      sidePanelDetails?.keyContacts?.isEnabled ||
      sidePanelDetails?.officeHours?.isEnabled
    );
  }, [appDetailedDescription, sidePanelDetails, applicationIntroductionVideoUrl]);

  const renderRichTextSection = (section: RichTextContentWidget | undefined, defaultTitle: string) => {
    if (!section?.isEnabled) return null;
    return (
      <ExpandableSection headerText={section?.title || defaultTitle} defaultExpanded={section?.isExpandedByDefault} variant="inline">
        <div
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(section?.richTextContent || '')
          }}
        />
      </ExpandableSection>
    );
  };

  if (isNoInformationResourcesAvailable) {
    return (
      <HelpPanel header={<h2>{headerName}</h2>}>
        <div>
          <p>{eFinSuiteMessages.NO_INFORMATION_INFO_PANEL}</p>
        </div>
      </HelpPanel>
    );
  }

  return (
    <HelpPanel header={<h2>{headerName}</h2>}>
      <SpaceBetween direction="vertical" size="m">
        {displayingInForm && (
          <Box padding={{ bottom: 'm' }}>
            <TextContent>
              <p>
                <small>{`View how your info panel looks as you enter content.`} </small>
              </p>
            </TextContent>
          </Box>
        )}
        {appDetailedDescription && <Box variant="p">{appDetailedDescription}</Box>}
        {applicationIntroductionVideoUrl && (
          <VideoThumbnail link={applicationIntroductionVideoUrl} category={`Intro Video`} displayTitle={`Intro Video`} />
        )}
        {renderRichTextSection(sidePanelDetails?.keyContacts, 'Key Contacts')}
        {renderRichTextSection(sidePanelDetails?.officeHours, 'Office Hours')}
      </SpaceBetween>
    </HelpPanel>
  );
};
