import { useEffect, useRef, useState } from 'react';
import { useContainerQuery } from '@amzn/awsui-component-toolkit';
import { load, remove, save } from 'src/utilities/LocalStorage';
import { StoredWidgetPlacement } from 'src/components/context/AppContextModels';

type LayoutHookReturn = readonly [
  React.Ref<any>,
  ReadonlyArray<StoredWidgetPlacement>,
  (layout: ReadonlyArray<StoredWidgetPlacement>) => void,
  () => void
];

/**
 * Custom hook to manage board items layout with localStorage persistence
 * @param storageKey - Key used for localStorage
 * @param defaultLayout - Default layout configuration
 */
export const useBoardItemsLayout = (storageKey: string, defaultLayout: ReadonlyArray<StoredWidgetPlacement>): LayoutHookReturn => {
  // Get container width and ref using containerQuery
  const [width, containerRef] = useContainerQuery((entry) => entry.contentBoxWidth);

  // Initialize layout state from localStorage or null
  const [layout, setLayout] = useState<ReadonlyArray<StoredWidgetPlacement> | null>(() => load(storageKey) ?? null);

  // Track if items have been modified
  const itemsChanged = useRef(layout !== null);

  // Set default layout when container width is available and items haven't changed
  useEffect(() => {
    if (itemsChanged.current || !width) {
      return;
    }
    setLayout(defaultLayout);
  }, [width, defaultLayout]);

  // Handle layout changes and persist to localStorage
  const handleLayoutChange = (newLayout: ReadonlyArray<StoredWidgetPlacement>) => {
    itemsChanged.current = true;
    save(storageKey, newLayout);
    setLayout(newLayout);
  };

  // Reset layout to default and clear localStorage
  const resetLayout = () => {
    itemsChanged.current = false;
    remove(storageKey);
    setLayout(defaultLayout);
  };

  return [containerRef, layout ?? [], handleLayoutChange, resetLayout] as const;
};
